import { Suspense, useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import { useGateValue, useStatsigUser } from '@statsig/react-bindings';

import {
  MPAnimations,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import SuspenselessErrorBoundary from 'components/ErrorBoundaries/SuspenselessErrorBoundary';
import Panel from 'components/panels/Panel';
import { convertAccountToStatsigUser } from 'components/StatsigProvider';
import ROUTES from 'constants/Routes';
import useSession from 'hooks/useSession';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import CSSGlobal from 'types/enums/css/Global';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import useNavbarGTM from 'utils/GTM/navbar';

import LinkSection, { NavLink } from './LinkSection';
import PanelProfileWallet from './PanelProfileWallet';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';
import * as styles from 'css/components/Navbar/Unified/ProfilePanel.module.css';

function PanelFooter() {
  const session = useSession();
  const Track = useNavbarGTM();
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { updateUserAsync } = useStatsigUser();

  const handleLogout = useCallback(async () => {
    Track.clickNavigationItem(ROUTES.LOGOUT());
    if (isConnected) await disconnectAsync();
    await updateUserAsync(convertAccountToStatsigUser());
  }, [Track, isConnected, disconnectAsync, updateUserAsync]);

  return (
    <div className={panelStyles.panelFooter}>
      <div className={joinClasses(CSSGlobal.Flex.Row, panelStyles.footerLinks)}>
        <Link
          className={joinClasses(
            'anchor',
            MPAnimations.Color.DarkToLight,
            MPColorClass.SolidNeutralGray4,
            MPFonts.paragraphXSmall
          )}
          to={ROUTES.LOGOUT()}
          onClick={handleLogout}
          reloadDocument
        >
          Logout
        </Link>
        {session.isCreator() && (
          <>
            <Link
              className={joinClasses(
                'anchor',
                MPAnimations.Color.DarkToLight,
                MPColorClass.SolidNeutralGray4,
                MPFonts.paragraphXSmall
              )}
              to={ROUTES.STORE.TRANSACTION_HISTORY()}
              onClick={() =>
                Track.clickNavigationItem(ROUTES.STORE.TRANSACTION_HISTORY())
              }
              reloadDocument
            >
              Blockchain Fees
            </Link>
            <Link
              className={joinClasses(
                'anchor',
                MPAnimations.Color.DarkToLight,
                MPColorClass.SolidNeutralGray4,
                MPFonts.paragraphXSmall
              )}
              to={ROUTES.STORE.ROYALTY_HISTORY()}
              onClick={() =>
                Track.clickNavigationItem(ROUTES.STORE.ROYALTY_HISTORY())
              }
              reloadDocument
            >
              Royalties
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

function ProfileHeader() {
  const session = useSession();
  const reloadDocument = useContext(IsDjangoContext);
  const Track = useNavbarGTM();

  return (
    <div className={styles.bannerContainer}>
      {session.account.store.aboutBanner ? (
        <img
          src={session.account.store.aboutBanner}
          className={styles.aboutBannerImage}
        />
      ) : (
        <div
          className={joinClasses(
            styles.aboutBannerImage,
            MPBackgroundColorClass.SolidNeutralGray5
          )}
        />
      )}
      <div
        className={joinClasses(
          styles.aboutBannerImageOverlay,
          MPBackgroundColorClass.SolidNeutralGray5
        )}
      />
      <div className={styles.profileInfo}>
        <Link
          className={joinClasses('invisibleAnchor', styles.profileImgLink)}
          to={ROUTES.PROFILE(session.account.username)}
          reloadDocument={reloadDocument}
          onClick={() => Track.clickNavigationItem('USER_PROFILE_LINK')}
        >
          <img className={styles.profileImg} src={session.getProfileImage()} />
        </Link>
        <Link
          className={joinClasses(MPFonts.headline4, 'invisibleAnchor')}
          to={ROUTES.PROFILE(session.account.username)}
          reloadDocument={reloadDocument}
          onClick={() => Track.clickNavigationItem('USER_PROFILE_LINK')}
        >
          {generateFormattedUserFullName(session.account.store.storeName)}
        </Link>
      </div>
    </div>
  );
}

function PanelWrapper({ children }) {
  const isMobile = useIsMobile();
  return isMobile ? children : <div className={styles.panel}>{children}</div>;
}

export default function ProfilePanel({ ...props }) {
  const modalProps = useMemo(
    () => ({
      container: window.parent.document.body,
    }),
    []
  );

  const isDjango = useContext(IsDjangoContext);
  const session = useSession();
  const isMobile = useIsMobile();
  const newWalletGate = useGateValue('gate_wallets_and_approvals_v3');

  return (
    <Panel
      isNav
      anchor="right"
      trackingTitle="Profile"
      {...props}
      ModalProps={modalProps}
    >
      <PanelWrapper>
        {!isMobile && <ProfileHeader />}
        <div className={panelStyles.bodyWithFooter}>
          <div className={joinClasses(styles.panelBody, panelStyles.panelBody)}>
            {!!isMobile && <ProfileHeader />}

            <SuspenselessErrorBoundary hideState>
              {isDjango ? (
                <></>
              ) : (
                <Suspense fallback={<></>}>
                  <PanelProfileWallet />
                </Suspense>
              )}
            </SuspenselessErrorBoundary>

            <LinkSection title="Artworks">
              {session.isCreator() && session.didCompleteOnboard() && (
                <>
                  <NavLink to={ROUTES.DIGITAL_MEDIA.CREATE()} reloadDocument>
                    Mint
                  </NavLink>
                  <NavLink to={ROUTES.IMPORT_TOKENS()} reloadDocument>
                    Import
                  </NavLink>
                </>
              )}
              <NavLink
                to={
                  session.isCreator()
                    ? ROUTES.PROFILE.SHOP(session.account?.username)
                    : ROUTES.PROFILE.GALLERY.COLLECTED(
                        session.account?.username
                      )
                }
                trackItem="YOUR_ARTWORKS_LINK"
                reloadDocument={isDjango}
              >
                Your&nbsp;Artworks
              </NavLink>
              <NavLink to={ROUTES.STORE.GIFTS()} reloadDocument>
                Send&nbsp;Artworks
              </NavLink>
            </LinkSection>
            <LinkSection title="Store">
              {!!newWalletGate && (
                <NavLink to={ROUTES.SETTINGS.WALLETS()} reloadDocument>
                  Wallets&nbsp;&&nbsp;Withdrawals
                </NavLink>
              )}
              {session.isCreator() && (
                <NavLink to={ROUTES.STORE()} reloadDocument>
                  Your&nbsp;Creations
                </NavLink>
              )}
              <NavLink
                to={
                  session.isCreator()
                    ? ROUTES.STORE.PRODUCTS()
                    : ROUTES.STORE.PRODUCTS.COLLECTED()
                }
                reloadDocument
                trackItem="ON_SALE_LINK"
              >
                On&nbsp;Sale
              </NavLink>
              <NavLink to={ROUTES.STORE.LIVE_OFFERS()} reloadDocument>
                Live&nbsp;Offers
              </NavLink>
              <NavLink to={ROUTES.STORE.PURCHASE_HISTORY()} reloadDocument>
                Sale&nbsp;History
              </NavLink>
              <NavLink to={ROUTES.YOUR.PURCHASES()} reloadDocument>
                Purchases&nbsp;&&nbsp;Bids
              </NavLink>
              {session.isCreator() && (
                <>
                  <NavLink to={ROUTES.STORE.REACTIONS()} reloadDocument>
                    Recent&nbsp;Activity
                  </NavLink>
                  <NavLink to={ROUTES.STORE.EDIT()} reloadDocument>
                    Store&nbsp;Settings
                  </NavLink>
                </>
              )}
              <NavLink to={ROUTES.STORE.EDIT.PROFILE()} reloadDocument>
                Account&nbsp;Settings
              </NavLink>
            </LinkSection>
            {!!isMobile && <PanelFooter />}
          </div>
          {!isMobile && <PanelFooter />}
        </div>
      </PanelWrapper>
    </Panel>
  );
}
