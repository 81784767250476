import { ForwardedRef, forwardRef, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTsTagAndProvenanceQuery } from 'graphql/__generated__/NFTsTagAndProvenanceQuery.graphql';
import { ProvenanceObjectType } from 'types/__generated__/graphql';

import ProfileIcon from 'components/avatar/ProfileIcon';
import InfoTooltip from 'components/InfoTooltip';
import ROUTES from 'constants/Routes';
import { DEFAULT_PROFILE_IMAGE_URL } from 'constants/Utils';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import generateTitleText from 'utils/generateTitleText';
import {
  convertToDate,
  getProvenanceFormattedFullName,
  getProvenancePriceString,
} from 'utils/nftUtils';

import * as styles from 'css/pages/product/ProductProvenance.module.css';

interface ProductProvenanceProps {
  nft: NFTsTagAndProvenanceQuery['response']['nfts']['edges'][0]['node'];
}

export default forwardRef(
  ({ nft }: ProductProvenanceProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} className={styles.provenanceGrid}>
      {nft.provenance.map((provenanceItem) => (
        <Fragment
          key={`${provenanceItem.type}${provenanceItem.dest}${provenanceItem.store?.pk}${provenanceItem.date}`}
        >
          <div className={styles.avatar}>
            <Link to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}>
              <ProfileIcon
                source={
                  provenanceItem.store
                    ? provenanceItem.store?.owner.profileImageUrl
                    : DEFAULT_PROFILE_IMAGE_URL
                }
                alt=""
                className={styles.provenanceAvatar}
              />
            </Link>
          </div>
          <div
            className={joinClasses(
              styles.provenanceContent,
              MPFonts.paragraphNormal
            )}
          >
            {provenanceItem.type === ProvenanceObjectType.Mint && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                minted{' '}
                <span className={MPFonts.textNormalMedium}>
                  {generateTitleText(nft)}
                </span>
              </div>
            )}
            {provenanceItem.type === ProvenanceObjectType.LazyMint && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                set{' '}
                <span className={MPFonts.textNormalMedium}>
                  {generateTitleText(nft)}
                </span>{' '}
                to be minted on demand.
                <InfoTooltip
                  className={styles.provenanceLazyMintInfo}
                  title="This artwork is available for sale, but has not been minted on the blockchain yet. Upon purchase, it will be minted and transferred to its new collector."
                />
              </div>
            )}
            {(provenanceItem.type === ProvenanceObjectType.Sale ||
              provenanceItem.type === ProvenanceObjectType.Listing) && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                listed for{' '}
                <span className={MPFonts.textNormalMedium}>
                  {getProvenancePriceString(provenanceItem)}
                </span>
              </div>
            )}
            {provenanceItem.type === ProvenanceObjectType.ProductBid && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                placed a bid for{' '}
                <span className={MPFonts.textNormalMedium}>
                  {getProvenancePriceString(provenanceItem)}
                </span>
              </div>
            )}
            {provenanceItem.type === ProvenanceObjectType.MediaBid && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                placed a global bid for{' '}
                <span className={MPFonts.textNormalMedium}>
                  {getProvenancePriceString(provenanceItem)}
                </span>
              </div>
            )}
            {provenanceItem.type === ProvenanceObjectType.Purchase && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                purchased for{' '}
                <span className={MPFonts.textNormalMedium}>
                  {getProvenancePriceString(provenanceItem)}
                </span>
              </div>
            )}
            {provenanceItem.type === ProvenanceObjectType.Transfer && (
              <div>
                <Link
                  to={ROUTES.PROFILE.SHOP(provenanceItem.store?.storeSlug)}
                  className={joinClasses(
                    MPFonts.textNormalMedium,
                    'defaultLink'
                  )}
                >
                  {getProvenanceFormattedFullName(provenanceItem)}
                </Link>{' '}
                transferred to{' '}
                <span className={MPFonts.textNormalMedium}>
                  {generateFormattedUserFullName(provenanceItem.dest)}
                </span>
              </div>
            )}
            <div className={joinClasses(MPFonts.paragraphSmall)}>
              {!!provenanceItem.date && convertToDate(provenanceItem.date)}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  )
);
