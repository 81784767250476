import {
  ActivityActionEnum,
  SortOrderEnum,
  SortTypeEnum,
} from 'types/__generated__/graphql';

import { ExploreType } from 'pages/explore/types';

const ROUTES = {
  ABOUT: Object.assign(() => '/about/', {
    VALUES: Object.assign(() => '/about/#values', {}),
  }),
  ACCOUNT_SETTINGS: Object.assign(() => '/profile/', {}),
  ACTIVITY: Object.assign(() => '/activity/', {
    WITH_DEFAULT_FILTERS: Object.assign(
      () =>
        `/activity/?actions=${ActivityActionEnum.Sale}~${ActivityActionEnum.Bid}~${ActivityActionEnum.Offer}&price=100~`,
      {}
    ),
    WITH_DROP_CREATORS_FILTER: Object.assign(
      (users: string[]) =>
        `/activity/?actions=${ActivityActionEnum.Sale}&userNames=${users.join(
          `~`
        )}`,
      {}
    ),
  }),
  ARTIST_DOWNGRADE_INFORMATION: Object.assign(
    () =>
      'https://rare.makersplace.com/2024/03/28/important-update-regarding-your-makersplace-artist-account/',
    {}
  ),
  AUTHENTICITY: Object.assign(
    (address, onChainId) => `/authenticity/${address}/${onChainId}`,
    {}
  ),
  COLLECTION_DROP: Object.assign(
    (slug: string) => `/drops/collection/${slug}/`,
    {}
  ),
  COLLECTORS: Object.assign(() => '/collectors/', {}),
  CONCIERGE: Object.assign(
    () =>
      'https://rare.makersplace.com/2022/09/20/introducing-makersplace-concierge/',
    {}
  ),
  CREATE_OPTIONS: Object.assign(() => '/create-options/', {}),
  CREATORS: Object.assign(() => '/creators/', {}),
  DIGITAL_MEDIA: Object.assign(() => '/digital-media/', {
    CREATE: Object.assign(() => '/digital-media/create/', {}),
    RELEASES: {
      ALL: Object.assign(() => '/digital-media/releases/all', {}),
      ID: Object.assign((id) => `/digital-media/releases/${id}`, {}),
    },
  }),
  DOWNLOAD: Object.assign(
    (address, onChainId) => `/download/${address}/${onChainId}`,
    {}
  ),
  EDITORIAL: Object.assign(() => 'https://rare.makersplace.com', {}),
  EXHIBITION: Object.assign((slug: string) => `/exhibition/${slug}/`, {}),
  EXHIBITIONS: Object.assign(() => '/exhibitions/', {}),
  EXHIBITS: Object.assign(() => '/exhibits', {
    AI_GENERATIVE_ART: Object.assign(() => '/exhibit/ai-generative-art', {}),
    PHYGITALS: Object.assign(() => '/exhibit/phygitals', {}),
  }),
  EXPLORE: {
    ARTWORKS: Object.assign(() => '/explore/artworks', {}),
    GENRE: Object.assign(
      (genreSlug) => `/explore/artworks?genre=${genreSlug}`,
      {}
    ),
    QUERY: Object.assign((query) => `/explore/artworks?query=${query}`, {}),
  },
  FAQ: Object.assign(() => '/faq/', {
    HOW_TO_SEND_CREATION: Object.assign(() => '/faq/#send-creation', {}),
    LEARN_MORE_SALE_CONTRACT: Object.assign(() => '/faq/#give-escrow', {}),
    SAVED_CREDIT_CARDS: Object.assign(() => '/faq/#saved-credit-cards', {}),
    SUPPORT: Object.assign(() => '/faq/#support', {}),
    THIRD_PARTY_AUTHENTIC: Object.assign(() => '/faq/#3rd-party-authentic', {}),
    WHAT_BUYING: Object.assign(() => '/faq/#what-buying', {}),
    WHAT_DEFERRAL: Object.assign(() => '/faq/#what-deferral', {}),
    WHAT_IS_BLOCKCHAIN_WALLET: Object.assign(() => '/faq/#wallet-address', {}),
  }),
  FORGOT_PASSWORD: Object.assign(() => '/forgot/password/', {}),
  HOME: Object.assign(() => '/', {
    WITH_EXPLORE: Object.assign(
      (exploreType: ExploreType) => `/#explore-${exploreType.toLowerCase()}`,
      {}
    ),
  }),
  IMPORT_TOKENS: Object.assign(() => '/import-tokens/', {}),
  LOGIN: Object.assign(() => '/login/', {
    LOGIN_NEXT: Object.assign(
      (nextUrl: string) => `/login/?next_url=${nextUrl}`,
      {}
    ),
  }),
  LOGOUT: Object.assign(() => '/logout/', {}),
  MARKETPLACE: Object.assign(() => '/marketplace/', {
    TAG: Object.assign(
      (tagSlug: string) => `/marketplace/?tags=${tagSlug}`,
      {}
    ),
    TRAITS: Object.assign(
      (typeSlug, valueSlug) =>
        `/marketplace/?collectionTraits=${typeSlug}%2C${valueSlug}&trait-${typeSlug}=${valueSlug}`
    ),
  }),
  NFT: Object.assign((nftSlug: string) => `/product/${nftSlug}`, {
    NFT_WITH_CAROUSEL: Object.assign(
      (nftSlug: string, carouselName: string) =>
        `/product/${nftSlug}/?carousel=${carouselName}`,
      {}
    ),
  }),
  OPEN_EDITION: Object.assign(
    (slug: string, id: string) => `/oe/${slug}-${id}`,
    {}
  ),
  PRIVACY: Object.assign(() => `/privacy/`, {}),
  PROFILE: Object.assign((globalSlug: string) => `/${globalSlug}`, {
    ACTIVITY: Object.assign(
      (globalSlug: string) => `/${globalSlug}/activity`,
      {}
    ),
    GALLERY: Object.assign((globalSlug: string) => `/${globalSlug}/gallery`, {
      COLLECTED: Object.assign(
        (globalSlug: string) => `/${globalSlug}/gallery/collected`,
        {}
      ),
      CREATED: Object.assign(
        (globalSlug: string) => `/${globalSlug}/gallery/created`,
        {}
      ),
    }),
    LIKES: Object.assign((globalSlug: string) => `/${globalSlug}/likes`, {}),
    SHOP: Object.assign((globalSlug: string) => `/${globalSlug}/shop`, {}),
    SHOP_WITH_FILTERS: Object.assign(
      (
        globalSlug: string,
        filters: { sortOrder?: SortOrderEnum; sortType?: SortTypeEnum } = {}
      ) => {
        const params = new URLSearchParams();
        if (filters.sortOrder) {
          params.append('sortOrder', filters.sortOrder);
        }
        if (filters.sortType) {
          params.append('sortType', filters.sortType);
        }
        const query = params.toString();
        return [`/${globalSlug}/shop`, query].filter(Boolean).join('?');
      },
      {}
    ),
  }),
  REGISTER: Object.assign(() => '/register/', {
    REGISTER_NEXT: Object.assign(
      (nextUrl: string) => `/register/?next_url=${nextUrl}`,
      {}
    ),
  }),
  SEARCH: Object.assign(() => '/search', {
    WITH_QUERY: Object.assign((query: string) => {
      const encodedQuery = encodeURIComponent(query);
      return `/search?q=${encodedQuery}`;
    }, {}),
  }),
  SETTINGS: {
    WALLETS: Object.assign(() => '/settings/wallets', {}),
  },
  STORE: Object.assign(() => '/store/', {
    CREATIONS: Object.assign(() => '/store/creations', {}),
    EDIT: Object.assign(() => '/store/edit/', {
      PROFILE: Object.assign(() => '/store/edit/profile/', {}),
    }),
    EDIT_ACCOUNT: Object.assign(() => '/store/edit-account/', {}),
    EDIT_STORE: Object.assign(() => '/store/edit-store/', {}),
    GIFTS: Object.assign(() => '/store/gifts/', {}),
    LIVE_OFFERS: Object.assign(() => '/store/live-offers/', {}),
    PRODUCTS: Object.assign(() => '/store/products/', {
      COLLECTED: Object.assign(() => '/store/products/collected/', {}),
    }),
    PURCHASE_HISTORY: Object.assign(() => '/store/purchase-history/', {}),
    REACTIONS: Object.assign(() => '/store/reactions/', {}),
    ROYALTY_HISTORY: Object.assign(() => '/store/royalty-history/', {}),
    TRANSACTION_HISTORY: Object.assign(() => '/store/transaction-history/', {
      RELEASE: Object.assign(
        (id: number) => `/store/transaction-history/release/${id}`,
        {}
      ),
    }),
  }),
  TERMS: Object.assign(() => '/terms/', {
    LICENSE_TO_ACCESS: Object.assign(() => '/terms/#license_to_access', {}),
  }),
  YOUR: Object.assign(() => '/your/', {
    BIDS: Object.assign(() => '/your/bids/', {}),
    PURCHASES: Object.assign(() => '/your/purchases/', {}),
  }),
};

export default ROUTES;
