:root {
  --pdp-rail-width: 343px;
  --pdp-padding: 32px;
  --pdp-related-text-container-height: 110px;
  --pdp-related-height-buffer: 50px;
}

.backToTopButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -100px;
  padding: 7.5px 22px;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 18px;
  border: 1px solid var(--color-grey100);
  backdrop-filter: blur(16px);
  background-color: var(--color-commonWhite);
  transition: all 300ms ease-in-out;
  cursor: pointer;
  color: var(--color-commonBlack);
}

.backToTopButton:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3); /* Add a subtle shadow on hover */
}

.productPage {
  height: calc(100vh - var(--navBar-height) - var(--pdp-padding));
  border: var(--pdp-padding) solid var(--color-backgroundDefault);
  border-top: none;
  overflow: auto;
  scroll-behavior: smooth;
}

.productPage :global(.defaultLink) {
  color: var(--color-commonBlack);

  &:hover {
    color: var(--color-SolidNeutralGray3);
    text-decoration: none;
  }

  &:active {
    color: var(--color-SolidNeutralGray1);
  }
}

.productPageContainer {
  background-color: var(--color-commonWhite);
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: var(--pdp-padding);
  justify-content: center;
  align-items: flex-start;
  padding: 0 var(--pdp-padding);
  position: relative;
}

.productGalleryFlex {
  display: flex;
  height: calc(100vh - var(--navBar-height) - var(--pdp-padding));
  box-sizing: border-box;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: var(--pdp-padding) 0;
  position: sticky;
  top: 0;
  width: 100%;
}

.slideOverrides {
  margin-top: 32px;
  padding: 0;
  height: 100%;
}

@media (--mq-is-mobile) {
  .productPage {
    border: none;
    height: auto;
  }

  .productPageContainer {
    border: none;
    flex-direction: column;
    gap: 0;
    height: auto;
    padding: var(--pdp-padding) 24px 24px;
  }

  .slideOverrides {
    margin-top: 0;
    padding: 0 24px;
  }

  .productGalleryFlex {
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
    top: initial;
  }
}

.protectedPage {
  height: 100%;
  padding: 0 24px;

  > .protectedForm {
    max-width: 439px;

    @media (--mq-is-mobile) {
      max-width: 100%;
    }
  }
}
