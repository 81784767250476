img.provenanceAvatar {
  border-radius: 100%;
  display: block;
  height: 46px;
  width: 46px;
}

.provenanceSection {
  margin: 8px 0 10px;
  max-height: inherit;
  overflow: hidden;
}

.provenanceSection:last-child {
  margin-bottom: 0;
}

.provenanceGrid {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-auto-rows: auto;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
}

.provenanceContent {
  min-height: 70px;
}

.avatar {
  position: relative;
}

.avatar::after {
  content: "";
  position: absolute;
  background-color: var(--color-SolidNeutralGray1);
  width: 2px;
  margin: 0 auto;
  inset: 50px 22px -10px;
}

.avatar:nth-last-child(2)::after {
  display: none;
}

.provenanceDate {
  margin-top: 2px;
  color: var(--color-SolidNeutralGray5);
}

.provenanceTabs {
  margin-bottom: 16px;
}

.provenanceTab {
  display: none;

  &.active {
    display: block;
  }
}

.productProvenanceMultiContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.productProvenanceMultiItem {
  display: flex;
}

.productProvenanceMultiItemAvatar {
  margin-right: 10px;
}

.productMultiItemDescription {
  margin: auto 0;
}

.productMultiItemDescriptionLink {
  text-decoration: none;
  color: var(--color-commonBlack);
}

.provenanceLazyMintInfo {
  display: inline-block;
  vertical-align: middle;
}
