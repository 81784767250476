/**
 * @generated SignedSource<<3274da8b744bf22e622714286b989164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAccountType = "CREATOR" | "CONSUMER" | "ANON" | "DOWNGRADED_CREATOR";
export type LoginArguments = {
  email: string;
  password: string;
  recaptchaToken?: string | null;
  twoFactorCode?: string | null;
  twoFactorSessionId?: string | null;
};
export type SessionLoginWithEmailMutation$variables = {
  requestData: LoginArguments;
};
export type SessionLoginWithEmailMutation$data = {
  readonly loginWithEmail: {
    readonly account: {
      readonly accountType: UserAccountType;
      readonly email: string;
      readonly id: string;
      readonly pk: string;
      readonly username: string;
    } | null;
    readonly isAuthentic: boolean | null;
    readonly token: string | null;
    readonly expireAt: any | null;
  } | null;
};
export type SessionLoginWithEmailMutation = {
  variables: SessionLoginWithEmailMutation$variables;
  response: SessionLoginWithEmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "LoginWithEmailMutation",
    "kind": "LinkedField",
    "name": "loginWithEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountAbstractType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAuthentic",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expireAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionLoginWithEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SessionLoginWithEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b3e8c640d9610bd9beb44805c005ee0",
    "id": null,
    "metadata": {},
    "name": "SessionLoginWithEmailMutation",
    "operationKind": "mutation",
    "text": "mutation SessionLoginWithEmailMutation(\n  $requestData: LoginArguments!\n) {\n  loginWithEmail(requestData: $requestData) {\n    account {\n      accountType\n      email\n      id\n      pk\n      username\n    }\n    isAuthentic\n    token\n    expireAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "712497daa7b115bb200fddbe386ca55f";

export default node;
