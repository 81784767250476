import {
  MPActionButton,
  MPBackgroundColorClass,
  MPDivider,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import User from 'components/accounts/User';
import useProductOwnership from 'hooks/product/useProductOwnership';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import { getExistingBid, getNFTPrivateSaleState } from 'utils/nftUtils';

import ProductAcceptOrRejectBid from './owner/ProductAcceptOrRejectBid';
import ProductBadges, { useBadges } from './ProductBadges';

import * as styles from 'css/pages/product/ProductDetails.module.css';

export interface ProductTopSectionProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  invalidate: () => void;
  nft: NFTType;
  transferCoreContract: NFTContractQuery$data['nftContract'];
}

export default function ProductTopSection({
  nft,
  invalidate,
  approvalRegistryContract,
  transferCoreContract,
}: ProductTopSectionProps) {
  const { hasBadges } = useBadges({
    nft,
    nftMetadata: nft.metadata,
  });
  const currencyDisplay = nft.listing.liveBid?.isCcBid
    ? CurrencyDisplayMode.USD
    : CurrencyDisplayMode.ETH;
  const session = useSession();
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const showBidAccept =
    getExistingBid(nft, currencyDisplay) > 0 &&
    isCurrentUserTokenOwner &&
    nft.onchainId &&
    session.isLoggedIn();
  const privateSaleState = getNFTPrivateSaleState(nft);
  const showPrivateSale =
    !isCurrentUserTokenOwner && privateSaleState.showPrivateSale;

  return (
    <>
      {!!showBidAccept && (
        <ProductAcceptOrRejectBid
          nft={nft}
          invalidate={invalidate}
          approvalRegistryContract={approvalRegistryContract}
          transferCoreContract={transferCoreContract}
        />
      )}
      {!!showPrivateSale && !!nft.listing.liveSale.curators?.length && (
        <div
          className={joinClasses(
            CSSGlobal.Flex.Col,
            CSSGap[12],
            MPBackgroundColorClass.BackgroundDefault,
            styles.productCuratorContainer
          )}
        >
          <div className={MPFonts.paragraphSmall}>
            This artwork is part of our Private Sales program, where we curate
            the finest contemporary works for our most discerning collectors.
            Please reach out if you have any questions.
          </div>

          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[20])}>
            {nft.listing.liveSale.curators.map((curator) => (
              <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[14])}>
                <User
                  user={curator}
                  bottomSection={
                    !!curator.jobTitle && (
                      <span className={MPFonts.textSmallRegular}>
                        {curator.jobTitle}
                      </span>
                    )
                  }
                />
                <MPActionButton fullWidth size="large" variant="secondary">
                  Contact {curator.fullName}
                </MPActionButton>
              </div>
            ))}
          </div>
        </div>
      )}
      {!!hasBadges && (
        <div className={styles.productBadgeContainer}>
          <div className={styles.productBadgeContainerText}>
            <ProductBadges nft={nft} nftMetadata={nft.metadata} />
          </div>
          <MPDivider />
        </div>
      )}
    </>
  );
}
