import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';
import { usePreloadedQuery } from 'react-relay';
import { Hash } from 'viem';

import { MPActionButton } from '@mp-frontend/core-components';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import {
  ContractNameEnum,
  CreatorContractType,
} from 'types/__generated__/graphql';

import { useSelectWalletConnectDialog } from 'components/wallet/SelectWalletConnectDialog';
import { APP_NAME } from 'constants/Utils';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import isWalletError from 'utils/errors/wallet';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import PendingApprovalsManager from '../wallet/wallets/ManageWalletDialog/Contracts/PendingApprovalsManager';

interface SetDigitalMediaSaleCoreButtonProps {
  contract: CreatorContractType;
  setError: Dispatch<SetStateAction<Error>>;
  walletAddress: Hash;
  onlyShowApproval?: boolean;
}

function SetDigitalMediaSaleCoreButton({
  contractQuery,
  contract,
  walletAddress,
  setError,
  onlyShowApproval = false,
}: {
  contractQuery: WithLoadQueryProps<NFTContractQuery>;
} & SetDigitalMediaSaleCoreButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [pollVal, setPollVal] = useState(0);
  const [prevVal, setPrevVal] = useState<boolean>(true);

  const { nftContract: digitalMediaSaleCoreContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      contractQuery.queryRef
    );
  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(contract.abidata).abi as any,
    contractAddress: contract.address as Hash,
  });

  const [isApproved, refetch, isFetching] =
    PendingApprovalsManager.useReadDigitalMediaSaleCoreApproval(walletAddress);

  // Linter automatically turns this into an arrow function.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      if (isApproved === undefined || isApproved === !prevVal) {
        if (!isFetching) {
          refetch?.();
        }
        const id = setTimeout(() => setPollVal((prev) => prev + 1), 1000);
        return () => clearTimeout(id);
      }
      setPrevVal(!prevVal);
      setIsPolling(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPolling, pollVal, prevVal, refetch, isFetching]);

  const digitalMediaSaleCoreApprovalManager = useSetOboApprovalForAll({
    account: walletAddress,
    isApproved: !isApproved,
    operatorAddress: digitalMediaSaleCoreContract.address as Hash,
  });

  const toggleDigitalMediaSaleCoreApprovalRef = useRef(noop);

  const [openSelectWalletDialog, selectWalletDialogJSX] =
    useSelectWalletConnectDialog(
      walletAddress,
      useCallback(() => toggleDigitalMediaSaleCoreApprovalRef.current(), [])
    );

  toggleDigitalMediaSaleCoreApprovalRef.current = useCallback(
    async (ev) => {
      ev?.preventDefault();
      if (isApproved === undefined) return;
      const wasApproved = !isApproved;
      try {
        setIsLoading(true);
        setError(undefined);
        await digitalMediaSaleCoreApprovalManager.mutate.writeAsync();
        setPrevVal(wasApproved);
        setIsPolling(true);
      } catch (e) {
        if (
          isWalletError.ConnectorNotConnected(e) ||
          isWalletError.ConnectorAccountNotFoundError(e)
        ) {
          openSelectWalletDialog();
        } else {
          setError(e);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      isApproved,
      digitalMediaSaleCoreApprovalManager,
      openSelectWalletDialog,
      setError,
    ]
  );

  return (
    <>
      {onlyShowApproval && isApproved !== false ? null : (
        <MPActionButton
          variant="primary"
          fullWidth
          size="large"
          onClick={toggleDigitalMediaSaleCoreApprovalRef.current}
          isLoading={isLoading || isPolling || isApproved === undefined}
        >
          {`${
            (isPolling ? !prevVal : isApproved) ? 'Revoke' : 'Grant'
          } ${APP_NAME} permission to list, sell, and transfer tokens on your behalf with standard royalties`}
        </MPActionButton>
      )}
      {selectWalletDialogJSX}
    </>
  );
}

const SetDigitalMediaSaleCoreButtonWithLoad = withLoadQuery(
  SetDigitalMediaSaleCoreButton,
  {
    contractQuery: { concreteRequest: NFTContractQueryType },
  }
);

export default function DigitalMediaSaleCoreButton(
  props: SetDigitalMediaSaleCoreButtonProps
) {
  return (
    <SetDigitalMediaSaleCoreButtonWithLoad
      {...props}
      contractQuery={{
        variables: { name: ContractNameEnum.DigitalMediaSaleCore },
      }}
    />
  );
}
