import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { noop } from 'lodash';
import { usePreloadedQuery } from 'react-relay';
import { useRecoilValue } from 'recoil';
import { Hash } from 'viem';
import { ErrorBoundaryProps } from '@sentry/react';

import {
  MPActionButton,
  MPActionButtonProps,
  MPFonts,
} from '@mp-frontend/core-components';
import { LoaderIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import { RecoilSaleContractQuery } from 'hooks/useSaleContract';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getTransactionUrl } from 'utils/nftUtils';

import PendingApprovalsManager from './ManageWalletDialog/Contracts/PendingApprovalsManager';

import { Wallets } from 'Session';

interface PendingApprovalDialogProps {
  approvedContract: boolean;
  isPolling: boolean;
  onCloseConfirmation: () => void;
  transactionId: Hash;
}

function PendingApprovalDialog({
  approvedContract,
  isPolling,
  transactionId,
  onCloseConfirmation,
}: PendingApprovalDialogProps) {
  const session = useSession();

  return approvedContract ? (
    isPolling ? (
      <StackStateDialog
        shouldBreak
        title="Granting Permission"
        open
        onClose={noop}
        actionButton={null}
      >
        <div className={joinClasses(CSSGlobal.Flex.CenteredCol, CSSGap[16])}>
          <LoaderIcon fontSize="68" />
          <div className={MPFonts.paragraphSmall}>
            We are now waiting for the transaction to confirm and will update
            here shortly. Transaction details can be seen on&nbsp;
            <a
              className="anchorV2"
              href={getTransactionUrl(
                session.contractNetworkUrl,
                transactionId
              )}
              target="_blank"
              rel="noreferrer"
            >
              Etherscan
            </a>
            .
          </div>
        </div>
      </StackStateDialog>
    ) : (
      <StackStateDialog
        shouldBreak
        title="Granted Permission"
        open
        onClose={noop}
        actionButton={
          <MPActionButton
            variant="primary"
            fullWidth
            size="large"
            onClick={onCloseConfirmation}
          >
            Close
          </MPActionButton>
        }
      >
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.paragraphSmall
          )}
        >
          You have successfully granted permission.
        </div>
      </StackStateDialog>
    )
  ) : null;
}

interface SetOBOButtonProps {
  setError: Dispatch<SetStateAction<ErrorBoundaryProps>>;
  setForcedDialogOpen: (boolean) => void;
  wallet: Wallets[0];
  variant?: MPActionButtonProps['variant'];
}

function SetOBOButton({
  approvalRegistryContractQuery,
  wallet,
  setError,
  setForcedDialogOpen,
  variant = 'primary',
}: {
  approvalRegistryContractQuery: WithLoadQueryProps<NFTContractQuery>;
} & SetOBOButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [approvedContract, setApprovedContract] = useState(false);
  const [pollVal, setPollVal] = useState(0);
  const [transactionId, setTransactionId] = useState<Hash>();

  const [isApproved, refetch, isFetching] =
    PendingApprovalsManager.useReadDigitalMediaSaleCoreApproval(wallet.address);

  // Linter automatically turns this into an arrow function.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      if (isApproved !== true) {
        if (!isFetching) {
          refetch?.();
        }
        const id = setTimeout(() => setPollVal((prev) => prev + 1), 1000);
        return () => clearTimeout(id);
      }
      setIsPolling(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPolling, pollVal, isApproved]);

  const { nftContract: approvalRegistryContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      approvalRegistryContractQuery.queryRef
    );
  const saleContract = useRecoilValue(RecoilSaleContractQuery);

  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(approvalRegistryContract.abidata).abi,
    contractAddress: approvalRegistryContract.address as Hash,
  });
  const registryOboApprovalManager = useSetOboApprovalForAll({
    isApproved: true,
    operatorAddress: saleContract.address,
  });

  const approveOBO = useCallback(
    async (ev) => {
      ev.preventDefault();
      try {
        setIsLoading(true);
        setError(undefined);
        setTransactionId(await registryOboApprovalManager.mutate.writeAsync());
        setApprovedContract(true);
        setIsPolling(true);
        setForcedDialogOpen(true);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [registryOboApprovalManager, setError, setForcedDialogOpen]
  );

  const onCloseConfirmation = useCallback(
    () => setForcedDialogOpen(false),
    [setForcedDialogOpen]
  );

  return (
    <>
      <MPActionButton
        fullWidth
        size="large"
        onClick={approveOBO}
        isLoading={isLoading || isPolling}
        variant={variant}
      >
        Grant&nbsp;Wallet&nbsp;Approval
      </MPActionButton>
      <PendingApprovalDialog
        approvedContract={approvedContract}
        isPolling={isPolling}
        transactionId={transactionId}
        onCloseConfirmation={onCloseConfirmation}
      />
    </>
  );
}

const ApprovedCreatorOBOButtonWithLoad = withLoadQuery(SetOBOButton, {
  approvalRegistryContractQuery: { concreteRequest: NFTContractQueryType },
});

export default function ApprovedCreatorOBOButton(props: SetOBOButtonProps) {
  return (
    <ApprovedCreatorOBOButtonWithLoad
      {...props}
      approvalRegistryContractQuery={{
        variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
      }}
    />
  );
}
