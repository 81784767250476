import { MouseEvent, useCallback, useMemo } from 'react';
import { useExplore } from 'pages/explore';
import { usePreloadedQuery } from 'react-relay';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import HomepageLiveExhibitionsQueryType, {
  HomepageLiveExhibitionsQuery,
} from 'graphql/__generated__/HomepageLiveExhibitionsQuery.graphql';

import ExhibitionCard from 'components/cards/ExhibitionCard';
import Carousel from 'components/carousel/Carousel';
import { useTrackingContext } from 'components/trackingContext';
import ROUTES from 'constants/Routes';
import { ExploreType } from 'pages/explore/types';
import { HomepageExhibitionType } from 'types/graphql/Drop';
import useHomepageGTM, { HomepageViewItem } from 'utils/GTM/homepage';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

interface LiveExhibitionsProps {
  exhibitionsQuery: WithLoadQueryProps<HomepageLiveExhibitionsQuery>;
}

function LiveExhibitions({
  exhibitionsQuery: { queryRef },
}: LiveExhibitionsProps) {
  const showExplore3 = useGateValue('gate_explore_v3');
  const explore = useExplore();
  const { source } = useTrackingContext();
  const track = useHomepageGTM();
  const result = usePreloadedQuery<HomepageLiveExhibitionsQuery>(
    HomepageLiveExhibitionsQueryType,
    queryRef
  );
  const exhibitions = useMemo(
    () =>
      result.topCarouselsUpcomingAndLive.edges.reduce(
        (memo, { node: exhibition }) => {
          if (!exhibition.sortedNftMetadatas.edges.length) {
            return memo;
          }

          memo.push(exhibition);
          return memo;
        },
        [] as HomepageExhibitionType[]
      ),
    [result]
  );

  const logViewAbleCardsToGA = useCallback(
    (start: number, end: number) => {
      const viewItems: HomepageViewItem[] = [];
      exhibitions.slice(start, end).forEach((drop) => {
        viewItems.push({
          currency: null,
          item_brand: drop.slug,
          item_category: 'exhibition',
          item_category4: 'Exhibition',
          item_id: drop.id,
          item_name: drop.dropTitle,
          price: 0,
          price_in_eth: 0,
          quantity: 0,
        });
      });
      track.viewItem(viewItems, 'live_exhibitions');
    },
    [track, exhibitions]
  );

  const handleViewAllClick = useCallback(
    (event: MouseEvent) => {
      if (!showExplore3) return;

      event.preventDefault();
      event.stopPropagation();
      explore.open(source, { type: ExploreType.Exhibitions });
    },
    [source, explore, showExplore3]
  );

  return exhibitions.length > 0 ? (
    <Carousel
      header={<>New Exhibitions</>}
      viewAllLink={ROUTES.EXHIBITIONS()}
      onViewAllClick={handleViewAllClick}
      containerName="new_exhibitions"
      logViewAbleCardsToGA={logViewAbleCardsToGA}
      items={exhibitions.map((exhibition, idx, array) => (
        <ExhibitionCard
          key={exhibition.id}
          className={joinClasses(styles.card, {
            [styles.last]: idx === array.length - 1,
          })}
          exhibition={exhibition}
        />
      ))}
    />
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    LiveExhibitions,
    { exhibitionsQuery: { concreteRequest: HomepageLiveExhibitionsQueryType } },
    { grouppedLoadingKey: 'homepage:live-exhibitions-carousel' }
  ),
  { errorFallback: null, suspenseless: true }
);
