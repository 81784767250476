import {
  ChangeEvent,
  KeyboardEvent,
  PureComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPInlineTextLinkButton,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses, KeyboardEventKey } from '@mp-frontend/core-utils';

import NFTlogViewMutationType, {
  NFTlogViewMutation,
} from 'graphql/__generated__/NFTlogViewMutation.graphql';
import NFTSType, { NFTsQuery } from 'graphql/__generated__/NFTsQuery.graphql';
import {
  ContractNameEnum,
  GraphPageViewObjectType,
  MpErrors,
} from 'types/__generated__/graphql';

import { APP_NAME } from 'constants/Utils';
import MPGraphQLError from 'errors/MPGraphQLError';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';
import { LOCAL_STORAGE_KEYS } from 'utils/localStorageUtils';

import ProductContainer from './ProductContainer';
import ProductLayoutContainer from './ProductLayoutContainer';

import * as styles from 'css/pages/product/ProductPage.module.css';

export function useGetNFTFromPreloadedQuery(queryRef) {
  const { nfts } = usePreloadedQuery<NFTsQuery>(NFTSType, queryRef);
  const nft = nfts.edges.length > 0 ? nfts.edges[0].node : null;
  return nft;
}

function ProductPage({ password = undefined }: { password?: string }) {
  const { nftSlug } = useParams();
  const [pageViewLogged, setPageViewLogged] = useState<boolean>(false);
  const [nftMetadata, setMetadata] = useState<NFTType['metadata']>(null);
  // This fetches minted and non minted token incase not live product.

  const [nftViewMutation] = useMutation<NFTlogViewMutation>(
    NFTlogViewMutationType
  );

  const nftPageView = useCallback(() => {
    if (nftMetadata && !pageViewLogged) {
      setPageViewLogged(true);
      nftViewMutation({
        variables: {
          objectId: parseInt(nftMetadata.pk, 10),
          objectType: GraphPageViewObjectType.Nft,
        },
      });
    }
  }, [nftMetadata, pageViewLogged, nftViewMutation]);

  useEffect(() => {
    nftPageView();
  }, [nftPageView]);

  return (
    <ProductLayoutContainer id="productPage">
      <ProductContainer
        approvalRegistryContractQuery={{
          variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
        }}
        nftsQuery={{
          variables: { first: 1, password, productSlug: nftSlug },
        }}
        transferCoreContractQuery={{
          variables: { name: ContractNameEnum.TransferCore },
        }}
        password={password}
        setMetadata={setMetadata}
      />
    </ProductLayoutContainer>
  );
}

type ErrorType = MPGraphQLError | Error;

export default class ProtectedProductPage<T> extends PureComponent<
  T,
  { error: ErrorType; password: string }
> {
  static getDerivedStateFromError(error: ErrorType) {
    return { error };
  }

  static willCatch(error: ErrorType) {
    return error?.name === MpErrors.Locked;
  }

  constructor(props) {
    super(props);
    const password =
      sessionStorage.getItem(LOCAL_STORAGE_KEYS.PRODUCT_PAGE_PASSWORD) || '';

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null, password };
  }

  componentDidCatch(error: ErrorType) {
    if (!ProtectedProductPage.willCatch(error)) throw error;
  }

  handleSubmit() {
    sessionStorage.setItem(
      LOCAL_STORAGE_KEYS.PRODUCT_PAGE_PASSWORD,
      this.state.password
    );
    this.resetErrorBoundary();
  }

  handlePasswordChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.setState({ password: event.currentTarget.value });
  }

  handlePasswordKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key !== KeyboardEventKey.enter) return;

    this.handleSubmit();
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    if (ProtectedProductPage.willCatch(this.state.error)) {
      return (
        <div
          className={joinClasses(
            styles.protectedPage,
            CSSGlobal.Flex.CenteredCol
          )}
        >
          <div
            className={joinClasses(
              MPColorClass.CommonBlack,
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.Col,
              CSSGap[16],
              styles.protectedForm
            )}
          >
            <div
              className={joinClasses(
                CSSGlobal.TextAlign.Centered,
                MPFonts.headline4
              )}
            >
              Welcome to Your Private Viewing Room
            </div>
            <div
              className={joinClasses(
                CSSGlobal.TextAlign.Centered,
                MPFonts.paragraphSmall
              )}
            >
              Please enter the code sent by the {APP_NAME} Concierge team.
            </div>
            <MPStyledTextField
              placeholder="Enter your code"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              onKeyPress={this.handlePasswordKeyPress}
              type="password"
            />
            <MPActionButton
              disabled={!this.state.password}
              onClick={this.handleSubmit}
            >
              Continue
            </MPActionButton>
            <div
              className={joinClasses(
                CSSGlobal.Flex.InlineRow,
                CSSGlobal.Flex.JustifyCenter,
                MPColorClass.SolidNeutralGray5
              )}
            >
              Didn&apos;t receive a code?&nbsp;
              <MPInlineTextLinkButton onClick={() => alert('Clicked!')}>
                Get in touch
              </MPInlineTextLinkButton>
            </div>
          </div>
        </div>
      );
    }

    return <ProductPage password={this.state.password} />;
  }
}
