.deleteIcon {
  background-color: var(--color-backgroundDefault);
  border: solid 1px var(--color-backgroundDefault);
  border-radius: 50%;
  color: var(--color-SolidNeutralGray5);
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.root {
  color: var(--color-commonBlack);
  cursor: default;
  border-radius: 80px;
  box-sizing: border-box;
  gap: 6px;
  height: 29px;
  padding: 0 16px;
  transition: color 300ms var(--mp-tense-out);

  &:hover {
    color: var(--color-SolidNeutralGray5);
  }

  &:active {
    color: var(--color-SolidNeutralGray3);
  }

  &.filled {
    border: solid 1px var(--color-SolidNeutralGray1);
    background-color: var(--color-SolidNeutralGray1);

    &:hover {
      background-color: var(--color-backgroundDefault);
      color: var(--color-SolidNeutralGray5);

      & .deleteIcon {
        border-color: var(--color-SolidNeutralGray1);
      }
    }

    &:active {
      background-color: var(--color-backgroundDefault);
      color: var(--color-SolidNeutralGray3);

      & .deleteIcon {
        border-color: var(--color-SolidNeutralGray1);
        color: var(--color-SolidNeutralGray1);
      }
    }
  }

  &.outlined {
    background-color: transparent;
  }
}

.label {
  padding: 0px;
}
