/**
 * @generated SignedSource<<5cf5829a4f231948ad627d233c4bd325>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountWalletApprovalsGrantedQuery$variables = {};
export type AccountWalletApprovalsGrantedQuery$data = {
  readonly walletApprovalsGranted: ReadonlyArray<{
    readonly pk: string;
    readonly address: string;
    readonly operatorContractAddress: `0x${string}` | null;
    readonly approvalContractAddress: `0x${string}` | null;
    readonly approvalContractAbidata: string | null;
    readonly canRevoke: boolean | null;
    readonly isObo: boolean | null;
    readonly approved: boolean;
  }> | null;
};
export type AccountWalletApprovalsGrantedQuery = {
  variables: AccountWalletApprovalsGrantedQuery$variables;
  response: AccountWalletApprovalsGrantedQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operatorContractAddress",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalContractAddress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalContractAbidata",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canRevoke",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isObo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountWalletApprovalsGrantedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApprovalType",
        "kind": "LinkedField",
        "name": "walletApprovalsGranted",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountWalletApprovalsGrantedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApprovalType",
        "kind": "LinkedField",
        "name": "walletApprovalsGranted",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ccfd87f32b779576cd7a1823d8c180db",
    "id": null,
    "metadata": {},
    "name": "AccountWalletApprovalsGrantedQuery",
    "operationKind": "query",
    "text": "query AccountWalletApprovalsGrantedQuery {\n  walletApprovalsGranted {\n    pk\n    address\n    operatorContractAddress\n    approvalContractAddress\n    approvalContractAbidata\n    canRevoke\n    isObo\n    approved\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "493eab08784dec2133fab365400a9291";

export default node;
