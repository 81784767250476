import { useCallback, useState } from 'react';
import { PreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import {
  ContractNameEnum,
  ProductTypeBidEnum,
} from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import { useCreditCardPaymentFormState } from 'hooks/product/usePaymentFormState';
import {
  usePurchaseWithCreditCard,
  usePurchaseWithEthereum,
} from 'hooks/product/usePurchaseProduct';
import useHasDatePassed from 'hooks/useHasDatePassed';
import useLoadQuery from 'hooks/useLoadQuery';
import CSSGlobal from 'types/enums/css/Global';
import { PurchasableNFTType } from 'types/graphql/NFT';
import { getGeneralError } from 'utils/flows/purchaseBids';
import { getNFTPresaleState, getNFTPrivateSaleState } from 'utils/nftUtils';

import ProductPendingOnChain from '../../ProductPendingOnChain';
import PaymentViewBody from './PaymentViewBody';
import PaymentViewDialog from './PaymentViewDialog';
import PaymentViewFooter from './PaymentViewFooter';
import PaymentViewForm from './PaymentViewForm';
import PaymentViewPricing from './PaymentViewPricing';
import PaymentViewReserveLockTimer from './PaymentViewReserveLockTimer';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

interface PaymentViewPurchaseProps {
  nft: PurchasableNFTType;
  onClose: () => void;
  onSuccess: () => void;
  reserveLockEndDate: Date;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

function PaymentViewPurchase({
  nft,
  reserveLockEndDate,
  depositFundContractQueryRef,
  stripeCardsQueryRef,
  onClose,
  onSuccess,
}: PaymentViewPurchaseProps & {
  depositFundContractQueryRef: PreloadedQuery<NFTContractQuery>;
}) {
  const [paymentMethod, setPaymentMethod] = useState<ProductTypeBidEnum>(
    ProductTypeBidEnum.Creditcard
  );
  const [paymentFormState, updateFormState] = useCreditCardPaymentFormState();
  const isReserveLockExpired = useHasDatePassed(reserveLockEndDate);

  const [purchaseWithCreditCard, purchaseWithCreditCardState] =
    usePurchaseWithCreditCard({
      ...paymentFormState,
      nft,
      onSuccess,
    });

  const [
    purchaseWithEthereum,
    purchaseWithEthereumState,
    purchaseWithEthereumTransaction,
    resetPurchaseWithEthereumTransaction,
  ] = usePurchaseWithEthereum({
    depositFundContractQueryRef,
    discountCode: paymentFormState.discountCode,
    nft,
    onSuccess,
  });

  const handleEthereumTransactionSuccess = useCallback(() => {
    if (purchaseWithEthereumTransaction) {
      resetPurchaseWithEthereumTransaction();
    }
    onSuccess();
  }, [
    onSuccess,
    purchaseWithEthereumTransaction,
    resetPurchaseWithEthereumTransaction,
  ]);

  const purchaseState =
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? purchaseWithCreditCardState
      : purchaseWithEthereumState;

  const { isPresaleActive, isPresaleEligible } = getNFTPresaleState(
    !!(paymentMethod === ProductTypeBidEnum.Creditcard
      ? nft.listing.liveSale?.custodialPresalePriceUsd
      : nft.listing.liveSale?.custodialPresalePriceEth),
    nft.metadata.dropMetadata
  );

  const generalError = getGeneralError(purchaseState);
  const isPrivateSale = getNFTPrivateSaleState(nft);
  const isWireTransfer = paymentMethod === ProductTypeBidEnum.WireTransfer;

  return (
    <PaymentViewDialog title="Make a Purchase" onClose={onClose}>
      {purchaseWithEthereumTransaction ? (
        <ProductPendingOnChain
          nft={nft}
          queryVariables={{
            txtId: purchaseWithEthereumTransaction,
          }}
          onSuccess={handleEthereumTransactionSuccess}
        />
      ) : (
        <>
          <PaymentViewBody>
            {generalError ? (
              <ErrorDisplay
                error={generalError}
                className={CSSGlobal.TextAlign.Centered}
              />
            ) : null}
            <PaymentViewForm
              nft={nft}
              paymentMethod={paymentMethod}
              stripeCardsQueryRef={stripeCardsQueryRef}
              onPaymentMethodChange={setPaymentMethod}
              onFormChange={updateFormState}
              acceptFiat={nft.listing.acceptsFiatPurchase}
              acceptWireTransfer={isPrivateSale?.showPrivateSale}
            />
            {!isWireTransfer && (
              <PaymentViewPricing
                amountInEth={
                  isPresaleActive && isPresaleEligible
                    ? nft.listing.liveSale.custodialPresalePriceEth
                    : nft.listing.lowestAskInEth
                }
                amountInUsd={
                  isPresaleActive && isPresaleEligible
                    ? nft.listing.liveSale.custodialPresalePriceUsd
                    : nft.listing.lowestAskInUsd
                }
                paymentMethod={paymentMethod}
                error={
                  purchaseState.mutationError || purchaseState.simulationError
                }
              />
            )}
          </PaymentViewBody>

          {!isWireTransfer && (
            <PaymentViewFooter
              submitTitle="Purchase"
              onSubmit={
                paymentMethod === ProductTypeBidEnum.Creditcard
                  ? purchaseWithCreditCard
                  : purchaseWithEthereum
              }
              isDisabled={isReserveLockExpired || purchaseState.isDisabled}
              isLoading={purchaseState.isValidating}
            >
              <PaymentViewReserveLockTimer
                classes={{
                  container: styles.reserved,
                  text: MPFonts.paragraphSmall,
                }}
                reserveLockEndDate={reserveLockEndDate}
                stringTemplates={{
                  expired: 'Your reservation has expired. Close and try again.',
                  notExpired: 'Reserved for the next $[minutes]m $[seconds]s',
                }}
              />
            </PaymentViewFooter>
          )}
        </>
      )}
    </PaymentViewDialog>
  );
}

export default function PaymentViewWrapper(props: PaymentViewPurchaseProps) {
  const [depositFundContractQueryRef] = useLoadQuery<NFTContractQuery>(
    NFTContractQueryType,
    {
      name: ContractNameEnum.DepositFund,
    }
  );

  return depositFundContractQueryRef ? (
    <PaymentViewPurchase
      {...props}
      depositFundContractQueryRef={depositFundContractQueryRef}
    />
  ) : null;
}
