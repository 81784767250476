import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ethers } from 'ethers';
import { PreloadedQuery } from 'react-relay';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import { ProductTypeBidEnum } from 'types/__generated__/graphql';

import Tabs from 'components/tabs/Tabs';
import { CreditCardPaymentFormState } from 'hooks/product/usePaymentFormState';
import useSession from 'hooks/useSession';
import { PurchasableNFTType } from 'types/graphql/NFT';
import sortArray from 'utils/array/sort';
import {
  NFTCardSelectionType,
  nftHasUserPlacedExistingRankedOffer,
} from 'utils/nftUtils';

import WalletConnection from '../WalletConnection';
import CreditCard from './CreditCard';
import WireTransfer from './WireTransfer';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

const DEFAULT_PAYMENT_METHODS = [
  ProductTypeBidEnum.Creditcard,
  ProductTypeBidEnum.Ether,
  ProductTypeBidEnum.WireTransfer,
];

const PaymentMethodLabel = {
  [ProductTypeBidEnum.Creditcard]: 'Credit Card',
  [ProductTypeBidEnum.Ether]: 'ETH',
  [ProductTypeBidEnum.WireTransfer]: 'Wire Transfer',
};

interface PaymentViewFormProps {
  acceptFiat: boolean;
  onFormChange: (formState: CreditCardPaymentFormState) => void;
  onPaymentMethodChange: (paymentMethod: ProductTypeBidEnum) => void;
  paymentMethod: ProductTypeBidEnum;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
  acceptWireTransfer?: boolean;
  children?: ReactNode;
  nft?: PurchasableNFTType;
  paymentMethods?: ProductTypeBidEnum[];
}

export default function PaymentViewForm({
  children,
  nft,
  paymentMethod,
  onFormChange,
  onPaymentMethodChange,
  stripeCardsQueryRef,
  paymentMethods = DEFAULT_PAYMENT_METHODS,
  acceptFiat,
  acceptWireTransfer = false,
}: PaymentViewFormProps) {
  const session = useSession();

  const [creditCardFullName, setCreditCardFullName] = useState(
    (!ethers.utils.isAddress(session.account?.fullName) &&
      session.account?.fullName) ||
      ''
  );
  const [creditCardIsValid, setCreditCardIsValid] = useState(false);
  const [discountCode] = useState('');
  const [creditCardSelectionType, setCreditCardSelectionType] = useState(
    NFTCardSelectionType.New
  );
  const [creditCardSelectedSavedId, setCreditCardSelectedSavedId] =
    useState('');
  const [creditCardRemember, setCreditCardRemember] = useState(false);

  const [tabs, setTabs] = useState<
    {
      label: string;
      to: string;
      value: ProductTypeBidEnum;
      disabled?: boolean;
      disabledMessage?: ReactNode;
    }[]
  >([]);

  const handleFormChange = useCallback(
    (event: CreditCardPaymentFormState) => onFormChange(event),
    [onFormChange]
  );

  useEffect(() => {
    const newTabs = sortArray(
      paymentMethods.reduce((acc, availablePaymentMethod) => {
        if (
          !acceptWireTransfer &&
          availablePaymentMethod === ProductTypeBidEnum.WireTransfer
        ) {
          return acc;
        }

        const isCreditCard =
          availablePaymentMethod === ProductTypeBidEnum.Creditcard;
        const isDifferentBidType =
          nft &&
          nftHasUserPlacedExistingRankedOffer(nft) &&
          availablePaymentMethod !== nft?.listing.rankedAuction.lastBid.bidType;

        const tabOptions =
          isCreditCard && !acceptFiat
            ? {
                disabled: true,
                disabledMessage:
                  'This creator only accepts ETH as a form of payment.',
              }
            : isDifferentBidType
            ? {
                disabled: true,
                disabledMessage:
                  'You may only bid in one currency type per account during this ranked auction.',
              }
            : {};

        acc.push({
          label: PaymentMethodLabel[availablePaymentMethod],
          to: '#',
          value: availablePaymentMethod,
          ...tabOptions,
        });

        return acc;
      }, []),
      ({ disabled: disabled1 }, { disabled: disabled2 }, index1, index2) => {
        if (disabled1 && !disabled2) {
          return 1;
        }
        if (!disabled1 && disabled2) {
          return -1;
        }
        return index1 - index2;
      }
    );

    setTabs(newTabs);
    onPaymentMethodChange(newTabs[0].value);
  }, [
    nft,
    onPaymentMethodChange,
    paymentMethods,
    acceptWireTransfer,
    acceptFiat,
  ]);

  useEffect(
    () =>
      handleFormChange({
        cardHolderName: creditCardFullName,
        cardIsValid: creditCardIsValid,
        cardSelectionType: creditCardSelectionType,
        discountCode,
        remember: creditCardRemember,
        savedCardId: creditCardSelectedSavedId,
      }),
    [
      creditCardFullName,
      creditCardIsValid,
      creditCardRemember,
      creditCardSelectedSavedId,
      creditCardSelectionType,
      discountCode,
      handleFormChange,
    ]
  );

  return (
    <div className={styles.paymentForm}>
      {tabs.length > 1 ? (
        <Tabs
          currentTab={paymentMethod}
          tabs={tabs}
          onChange={(event: SyntheticEvent, value: string) =>
            onPaymentMethodChange(value as ProductTypeBidEnum)
          }
        />
      ) : null}

      {{
        [ProductTypeBidEnum.Creditcard]: !!stripeCardsQueryRef && (
          <CreditCard
            stripeCardsQueryRef={stripeCardsQueryRef}
            type={creditCardSelectionType}
            onTypeSelect={setCreditCardSelectionType}
            onDefaultIdChange={setCreditCardSelectedSavedId}
            cardholderName={creditCardFullName}
            onCardholderNameChange={setCreditCardFullName}
            remember={creditCardRemember}
            onRememberChange={setCreditCardRemember}
            onCardElementChange={({ complete, error, empty }) =>
              setCreditCardIsValid(
                complete && !empty && error?.type !== 'validation_error'
              )
            }
          />
        ),
        [ProductTypeBidEnum.Ether]: <WalletConnection />,
        [ProductTypeBidEnum.WireTransfer]: (
          <WireTransfer amount={nft.listing?.lowestAskInUsd} />
        ),
      }[paymentMethod] || null}

      {children}
    </div>
  );
}
