import { useCallback, useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import MPStandardDialog from '../dialog/MPStandardDialog';
import { ExternalLinkIcon } from '../icons';

import * as styles from '../css/collapsibles/MPExpandableText.module.css';

interface MPExpandableTextProps {
  content: string;
  contentBoldClassName?: string;
  contentClassName?: string;
  lineClamp?: number;
  moreText?: string;
  title?: string;
  useMarkdown?: boolean;
}

function CustomLink({ href, children, ...props }) {
  return (
    <a href={href} {...props}>
      {children} <ExternalLinkIcon fontSize="12" />
    </a>
  );
}

function MarkdownContent({
  content,
  className,
}: {
  className: string;
  content: string;
}) {
  return (
    <Markdown
      options={{
        overrides: {
          a: {
            component: CustomLink,
            props: { className },
          },
        },
      }}
    >
      {content}
    </Markdown>
  );
}

export default function MPExpandableText({
  content,
  lineClamp = 3,
  title = '',
  moreText = 'View More',
  contentClassName = MPFonts.paragraphNormal,
  contentBoldClassName = MPFonts.textSmallSemiBold,
  useMarkdown = false,
}: MPExpandableTextProps) {
  const [clampedDiv, setRef] = useRefState<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState<
    [string | number, string | number]
  >(['auto', 'auto']);

  const collapse = useCallback(() => setExpanded(false), []);
  const expand = useCallback(() => setExpanded(true), []);
  const collapseOnEnter = useOnEnterKey(collapse);

  useEffect(() => {
    setClamped(false);
    setExpanded(false);
  }, [content]);

  // Not handling resize as this is fixed width, rechecking on resize also won't work as we wouldn't know the clamped height if resized when expanded.
  useEffect(() => {
    if (clampedDiv) {
      if (clampedDiv.scrollHeight > clampedDiv.clientHeight) {
        setDescriptionMaxHeight([
          clampedDiv.clientHeight,
          clampedDiv.scrollHeight,
        ]);
      }
      setClamped(clampedDiv.scrollHeight > clampedDiv.clientHeight);
    }
  }, [content, clampedDiv]);

  // The typings incorrectly complain on string literals, so not inlining.
  const style = {
    '--line-clamp': lineClamp,
    maxHeight: descriptionMaxHeight[isExpanded ? 1 : 0],
  };

  return (
    <div className={styles.container}>
      <div
        ref={setRef}
        style={style}
        className={joinClasses(
          styles.content,
          styles.contentLink,
          contentClassName
        )}
      >
        {useMarkdown ? (
          <MarkdownContent content={content} className={contentBoldClassName} />
        ) : (
          content
        )}
      </div>
      {!!isClamped && (
        <>
          <span
            className={joinClasses(MPFonts.textSmallBold, styles.viewMore)}
            onClick={expand}
            onKeyDown={collapseOnEnter}
            role="button"
            tabIndex={0}
          >
            {moreText}
          </span>
          <MPStandardDialog title={title} open={isExpanded} onClose={collapse}>
            <div
              className={joinClasses(
                MPFonts.textSmallRegular,
                styles.contentLink
              )}
            >
              {useMarkdown ? (
                <MarkdownContent
                  content={content}
                  className={contentBoldClassName}
                />
              ) : (
                content
              )}
            </div>
          </MPStandardDialog>
        </>
      )}
    </div>
  );
}
