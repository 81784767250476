import { useCallback } from 'react';

import {
  MPFonts,
  MPTab,
  MPTabsVariant,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import Tabs from 'components/tabs/Tabs';

import { GenreTabs } from '../genre';
import useArtworkFilterState from './UseArtworkFilterState';

import * as styles from 'css/pages/explore2/artwork/GenreFilterTabs.module.css';

const sx = { cursor: 'pointer' };

export default function GenreFilterTabs() {
  const filterParams = useArtworkFilterState();
  const [getGenre, setGenre] = filterParams.genre;
  const onChange = useCallback((e, val) => setGenre(val), [setGenre]);
  const isMobile = useIsMobile();

  return (
    <div className={joinClasses('flexCenter', styles.tabs)}>
      <Tabs
        currentTab={getGenre()}
        design={MPTabsVariant.Text}
        tabs={GenreTabs}
        onChange={onChange}
        tabsSx={sx}
        TabComponent={{
          component: undefined,
          props: {
            tabFont: isMobile ? MPFonts.textNormalMedium : MPFonts.headline4,
          },
          type: MPTab as any,
        }}
      />
    </div>
  );
}
