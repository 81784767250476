/* eslint-disable jsx-a11y/anchor-is-valid */
/* TODO: should be using the Link from react-router-dom. */
import { startTransition, useState } from 'react';
import { noop } from 'lodash';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { Link } from '@mui/material';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPFormTextInput,
  MPTooltip,
} from '@mp-frontend/core-components';
import { InfoIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountChangeFullNameAndEmail, {
  AccountChangeFullNameAndEmailMutation,
} from 'graphql/__generated__/AccountChangeFullNameAndEmailMutation.graphql';
import AccountChangePrimaryWallet, {
  AccountChangePrimaryWalletMutation,
} from 'graphql/__generated__/AccountChangePrimaryWalletMutation.graphql';
import AccountDisconnectWallet, {
  AccountDisconnectWalletMutation,
} from 'graphql/__generated__/AccountDisconnectWalletMutation.graphql';
import AccountEmailVerification, {
  AccountEmailVerificationQuery,
} from 'graphql/__generated__/AccountEmailVerificationQuery.graphql';
import AccountInvalidateSessions, {
  AccountInvalidateSessionsMutation,
} from 'graphql/__generated__/AccountInvalidateSessionsMutation.graphql';
import AccountResendVerificationEmail, {
  AccountResendVerificationEmailMutation,
} from 'graphql/__generated__/AccountResendVerificationEmailMutation.graphql';
import AccountWalletApprovalsGranted, {
  AccountWalletApprovalsGrantedQuery,
} from 'graphql/__generated__/AccountWalletApprovalsGrantedQuery.graphql';
import { MpErrors } from 'types/__generated__/graphql';

import DefaultSuspense from 'components/DefaultSuspense';
import WalletActionButton from 'components/WalletActionButton';
import ROUTES from 'constants/Routes';
import useLoadQuery from 'hooks/useLoadQuery';
import useRegisterWallet from 'hooks/useRegisterWallet';
import useSession, { useRefreshSession } from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import getAddressDisplay from 'utils/getAddressDisplay';
import { HexString } from 'utils/jwt/walletUtils';

import SideNavBar1point5 from '../SideNavBar1point5';
import StoreLayout from '../StoreLayout';
import ChangePasswordDialog from './ChangePasswordDialog';
import CreditCardSection from './CreditCardSection';
import RevokeButton from './RevokeButton';
import TwoFactorCollectPhoneDialog from './TwoFactorCollectPhoneDialog';
import TwoFactorDialog from './TwoFactorDialog';

import * as styles from 'css/pages/store/EditAccountPage.module.css';

import { SessionType } from 'Session';

interface CreatorAddressSettingProps {
  wallet: SessionType['account']['wallets'][number];
}

// TODO, UI polish in personal creator address UI area
//  https://app.asana.com/0/1203777173591121/1204222142704310/f
function CreatorAddressSetting({ wallet }: CreatorAddressSettingProps) {
  const refreshSession = useRefreshSession();
  const [changePrimaryWallet, isChanging] =
    useMutation<AccountChangePrimaryWalletMutation>(AccountChangePrimaryWallet);
  const [changePrimaryWalletError, setChangePrimaryWalletError] = useState('');
  const { address, isCustodialCreatorWallet, isSelectedMintingWallet } = wallet;
  const makeWalletPrimaryCallback = () =>
    changePrimaryWallet({
      onCompleted: () => {
        startTransition(refreshSession);
      },
      onError: (error) => {
        if (error.name === MpErrors.WalletIsAlreadyPrimary) {
          startTransition(refreshSession);
        } else {
          setChangePrimaryWalletError(error.message);
        }
      },
      variables: { address },
    });
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>{address}</td>
            <td>
              {isSelectedMintingWallet ? (
                <div className={MPFonts.textNormalMedium}>
                  {isCustodialCreatorWallet ? (
                    <>(Your primary address / MakersPlace Issued)</>
                  ) : (
                    <>(Your primary address / Personal Wallet)</>
                  )}
                </div>
              ) : (
                <>&nbsp;</>
              )}
            </td>
            <td>
              {!isSelectedMintingWallet ? (
                <MPActionButton
                  type="button"
                  onClick={makeWalletPrimaryCallback}
                  isLoading={isChanging}
                >
                  Set as Primary
                </MPActionButton>
              ) : (
                <>&nbsp;</>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {!changePrimaryWalletError && (
        <div className="errorMsgColor">{changePrimaryWalletError}</div>
      )}
    </>
  );
}

function RegisterCollectionWalletToUserButton() {
  const { error, isError, isLoading, registerWallet } = useRegisterWallet();

  return (
    <>
      {!!isError && (
        <div className={joinClasses('errorMsgColor', 'defaultTextMargin')}>
          {error?.message ?? 'Something went wrong, please try again later.'}
        </div>
      )}
      <WalletActionButton
        type="button"
        size="large"
        onClick={() => registerWallet(false)}
        isLoading={isLoading}
      >
        Connect a Collection Wallet
      </WalletActionButton>
    </>
  );
}

function DisconnectPersonalWalletButton({ children, address, isAnonAccount }) {
  const refreshSession = useRefreshSession();
  const [disconnectPersonalWallet, isChanging] =
    useMutation<AccountDisconnectWalletMutation>(AccountDisconnectWallet);
  const [disconnectError, setDisconnectError] = useState('');
  const disconnectPersonalWalletCallback = () =>
    disconnectPersonalWallet({
      onCompleted: () => {
        startTransition(refreshSession);
      },
      onError: (error) => {
        setDisconnectError(error.message);
      },
      variables: { address: address as HexString },
    });
  return (
    <>
      <MPActionButton
        type="button"
        onClick={disconnectPersonalWalletCallback}
        isLoading={isChanging}
        disabled={isAnonAccount}
      >
        {children}
      </MPActionButton>
      {!!isAnonAccount && (
        <div>
          *You need to complete adding your profile details to disconnect
        </div>
      )}
      {!!disconnectError && (
        <div className="errorMsgColor">{disconnectError}</div>
      )}
    </>
  );
}

interface PersonalCreatorWalletConnectedToDbProps {
  personalCreatorWallet: string;
}

function ConnectPersonalCreatorWalletToDb() {
  const { error, isError, isLoading, registerWallet } = useRegisterWallet();
  const [displayConnectEthWallet, setDisplayConnectEthWallet] = useState(false);
  const toggleDisplayConnectEthWallet = () =>
    setDisplayConnectEthWallet(!displayConnectEthWallet);

  return (
    <>
      <Link
        className={joinClasses(MPFonts.textSmallSemiBold, styles.actionLink)}
        target="_blank"
        onClick={toggleDisplayConnectEthWallet}
      >
        Connect a Personal Digital Wallet
      </Link>
      {!!displayConnectEthWallet && (
        <>
          <div className={styles.block}>
            <div className={styles.textAlignCenter}>--</div>
            Create and sell your creations using&nbsp;
            <span className={MPFonts.textNormalMedium}>
              your personal wallet
            </span>
            . We recommend using your personal wallet if you&apos;re already
            familiar with blockchain technology.
          </div>
          {!!isError && (
            <div className={joinClasses('errorMsgColor', 'defaultTextMargin')}>
              {error?.message ??
                'Something went wrong, please try again later.'}
            </div>
          )}
          <WalletActionButton
            type="button"
            onClick={() => registerWallet(true)}
            size="large"
            isLoading={isLoading}
          >
            Connect an Ethereum Wallet
          </WalletActionButton>
        </>
      )}
    </>
  );
}

function PersonalCreatorWalletConnectedToDb({
  personalCreatorWallet,
}: PersonalCreatorWalletConnectedToDbProps) {
  return (
    <>
      <div className={styles.textAlignCenter}>--</div>
      You&apos;ve connected your personal wallet to manage and sell your
      creations. If you wish to remove this wallet, please contact&nbsp;
      <a href="mailto:supporthelp@makersplace.com">
        supporthelp@makersplace.com
      </a>
      .
      <br />
      Personal Wallet: <b>{personalCreatorWallet}</b>
    </>
  );
}

interface EditAccountContentsProps {
  emailVerificationQueryRef: PreloadedQuery<AccountEmailVerificationQuery>;
  walletApprovalsGrantedQueryRef: PreloadedQuery<AccountWalletApprovalsGrantedQuery>;
}

function EditAccountContents({
  emailVerificationQueryRef,
  walletApprovalsGrantedQueryRef,
}: EditAccountContentsProps) {
  const session = useSession();

  const { email, fullName, username, wallets, isAnonAccount } = session.account;

  const walletRegexp = /^0x([0-9a-fA-F]{40})$/;
  const isWalletString = (s: string) => s.match(walletRegexp) != null;

  const [changeEmailError, setChangeEmailError] = useState<string>('');
  const [changeUsernameError, setChangeUsernameError] = useState<string>('');
  const refreshSession = useRefreshSession();
  const [accountState, setAccountState] = useState({
    email: isAnonAccount ? '' : email,
    fullName: isWalletString(fullName) ? '' : fullName,
    username: isWalletString(username) ? '' : username,
  });

  const { emailVerification } =
    usePreloadedQuery<AccountEmailVerificationQuery>(
      AccountEmailVerification,
      emailVerificationQueryRef
    );

  const { walletApprovalsGranted } =
    usePreloadedQuery<AccountWalletApprovalsGrantedQuery>(
      AccountWalletApprovalsGranted,
      walletApprovalsGrantedQueryRef
    );

  const [resendVerificationEmail, isResendingVerificationEmail] =
    useMutation<AccountResendVerificationEmailMutation>(
      AccountResendVerificationEmail
    );

  const [resendVerificationState, setResendVerificationState] =
    useState<string>();

  // Michael what is this? This thing is undefined always
  const clickResendVerificationEmail = () => {
    resendVerificationEmail({
      onCompleted: () => setResendVerificationState('success'),
      onError: () => setResendVerificationState('error'),
      variables: {},
    });
  };

  const [changeFullNameAndEmail, isChangingFullNameAndEmail] =
    useMutation<AccountChangeFullNameAndEmailMutation>(
      AccountChangeFullNameAndEmail
    );

  const updateAccountFullNameAndEmail = () => {
    changeFullNameAndEmail({
      onCompleted: (result) => {
        setChangeEmailError(null);
        setChangeUsernameError(null);
        setAccountState({
          email:
            isAnonAccount &&
            accountState.email !== result.changeFullNameAndEmail.account.email
              ? ''
              : result.changeFullNameAndEmail.account.email,
          fullName:
            isWalletString(result.changeFullNameAndEmail.account.fullName) &&
            accountState.fullName !==
              result.changeFullNameAndEmail.account.fullName
              ? ''
              : result.changeFullNameAndEmail.account.fullName,
          username:
            isWalletString(result.changeFullNameAndEmail.account.username) &&
            accountState.username !==
              result.changeFullNameAndEmail.account.username
              ? ''
              : result.changeFullNameAndEmail.account.username,
        });
        startTransition(refreshSession); // TODO michael if we don't do this, then verify email isn't displayed after email update
      },
      onError: (error) => {
        setChangeEmailError(
          error.name === MpErrors.EmailIsInvalid ? error.message : ''
        );
        setChangeUsernameError(
          error.name === MpErrors.SlugConflict ||
            error.name === MpErrors.SlugEthereum
            ? error.message
            : null
        );
      },
      variables: accountState,
    });
  };

  const [isTwoFactorDialogOpen, openTwoFactorDialog, closeTwoFactorDialog] =
    useSimpleDialogController({ preventDefault: true });
  const [email2FAError, setEmail2FAError] = useState('');
  const enableEmail2FA = () => openTwoFactorDialog();

  const [
    isTwoFactorCollectPhoneDialogOpen,
    openTwoFactorCollectPhoneDialog,
    closeTwoFactorCollectPhoneDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  const [sms2FAError, setSMS2FAError] = useState('');
  const enableSMS2FA = () => openTwoFactorCollectPhoneDialog();

  const [invalidateSessions, isInvalidatingSessions] =
    useMutation<AccountInvalidateSessionsMutation>(AccountInvalidateSessions);
  const [invalidateSessionsError, setInvalidateSessionsError] = useState('');

  const logOutOfAllOtherSessions = () =>
    invalidateSessions({
      onCompleted: noop,
      onError: (error) => setInvalidateSessionsError(error.message),
      variables: {},
    });

  // TODO: Stubs for functionality to be added when mutations are ready
  const [
    isChangePasswordDialogOpen,
    openChangePasswordDialog,
    closeChangePasswordDialog,
  ] = useSimpleDialogController({ preventDefault: true });

  const personalCreatorWallet = wallets.find(
    (wallet) => !wallet.isCustodialCreatorWallet
  );
  const isCreator = wallets.some((wallet) => wallet.isCustodialCreatorWallet);
  const personalWallets = wallets.filter(
    (wallet) => !wallet.isCustodialCreatorWallet
  );

  return (
    <StoreLayout>
      <h2 className={MPFonts.titleMedium}>Account Settings</h2>

      <br />

      <div className={MPFonts.bodyMedium}>
        Your full name will be shown in every proof of authenticity.
      </div>

      <br />

      {/* TODO: convert this to a Formik form */}
      <MPFormTextInput
        label="Full Name"
        type="text"
        fullWidth
        value={accountState.fullName}
        onChange={(e) =>
          setAccountState({
            ...accountState,
            fullName: e.target.value,
          })
        }
      />
      <br />
      <br />
      <MPFormTextInput
        label="Username"
        type="text"
        fullWidth
        error={!!changeUsernameError}
        value={accountState.username}
        onChange={(e) =>
          setAccountState({
            ...accountState,
            username: e.target.value,
          })
        }
      />
      {!!changeUsernameError && (
        <div className="errorMsgColor">{changeUsernameError}</div>
      )}

      <br />
      <br />
      <MPFormTextInput
        label="Email"
        type="email"
        fullWidth
        error={!!changeEmailError}
        value={accountState.email}
        onChange={(e) =>
          setAccountState({
            ...accountState,
            email: e.target.value,
          })
        }
      />
      {!!changeEmailError && (
        <div className="errorMsgColor">{changeEmailError}</div>
      )}

      <br />
      {!emailVerification?.hasVerified && !isAnonAccount && (
        <div
          className={joinClasses(
            styles.resendVerificationEmailText,
            MPFonts.bodyMedium
          )}
        >
          Your email hasn&#39;t been verified.&nbsp;
          <Link onClick={clickResendVerificationEmail}>
            Click to resend verification email
          </Link>
        </div>
      )}
      {!!emailVerification &&
        !isResendingVerificationEmail &&
        !emailVerification.hasVerified && (
          <div
            className={joinClasses(
              styles.resendVerificationEmailFeedback,
              MPFonts.bodySmall
            )}
          >
            {resendVerificationState === 'success' && (
              <div>
                We&apos;ve resent your confirmation email.
                <br />
                Please check your spam folder if you don&apos;t see it,
                <br />
                or contact us at&nbsp;
                <a href="mailto:supporthelp@makersplace.com">
                  supporthelp@makersplace.com
                </a>
                .
              </div>
            )}
            {resendVerificationState === 'error' && (
              <div className={MPColorClass.ErrorMain}>
                We couldn&apos;t resend your email verification, please try
                again.
              </div>
            )}
          </div>
        )}

      <br />
      <br />

      <Link
        onClick={openChangePasswordDialog}
        className={MPFonts.textSmallSemiBold}
      >
        Change password
      </Link>
      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        cancel={closeChangePasswordDialog}
      />

      <br />
      <br />
      <br />

      <MPActionButton
        type="button"
        size="large"
        onClick={updateAccountFullNameAndEmail}
        disabled={isChangingFullNameAndEmail}
      >
        Update
      </MPActionButton>

      <br />
      <br />
      <br />
      <br />

      {/** ** Creator Wallet Flow *** */}
      {!!isCreator && (
        <>
          <h4 className={MPFonts.titleSmall}>
            Blockchain Wallets for&nbsp;
            <span className="underline">Creating Artworks</span>
          </h4>
          {wallets
            .filter((wallet) => wallet.isCreatorWallet)
            .map((wallet) => (
              <CreatorAddressSetting wallet={wallet} />
            ))}
          <br />
          {!personalCreatorWallet?.address ? (
            <ConnectPersonalCreatorWalletToDb />
          ) : (
            <PersonalCreatorWalletConnectedToDb
              personalCreatorWallet={personalCreatorWallet?.address}
            />
          )}
        </>
      )}

      <br />

      {/* TODO: use a pop-up dialog similar to UploadNFTPage. */}
      <Link
        href={ROUTES.FAQ.WHAT_IS_BLOCKCHAIN_WALLET()}
        className={joinClasses(MPFonts.textSmallSemiBold, styles.actionLink)}
        target="_blank"
      >
        What is a blockchain wallet?
      </Link>

      {/* Collection Wallet Flow */}
      <h4 className={MPFonts.titleSmall}>
        Blockchain Wallets for your{' '}
        <span className="underline">Personal Collection</span>
      </h4>
      {personalWallets.length === 0 ? (
        <p className={MPFonts.bodyMedium}>No wallets have been connected.</p>
      ) : (
        <table>
          {personalWallets.map((personalWallet) => (
            <tr>
              <td>{personalWallet.address}</td>
              <td>
                {isAnonAccount || personalWallet.canDisconnect ? (
                  <DisconnectPersonalWalletButton
                    address={personalWallet.address}
                    isAnonAccount={isAnonAccount}
                  >
                    Disconnect
                  </DisconnectPersonalWalletButton>
                ) : (
                  <MPTooltip
                    title="This is your personal wallet used to manage and sell your creations. If you wish to remove this wallet, please contact supporthelp@makersplace.com."
                    placement="right"
                    arrow
                  >
                    <InfoIcon />
                  </MPTooltip>
                )}
              </td>
            </tr>
          ))}
        </table>
      )}

      <br />

      <RegisterCollectionWalletToUserButton />

      <br />
      <br />
      <br />
      <br />

      {/* Wallet Approvals */}
      <h4 className={MPFonts.titleSmall}>Wallet Approvals Granted</h4>

      <div className={MPFonts.bodyMedium}>
        {(walletApprovalsGranted &&
          walletApprovalsGranted.map((approval) => (
            <div key={approval.pk} className={styles.walletApproval}>
              <span className="bold">
                {getAddressDisplay(approval.address)}
              </span>
              &nbsp;approved&nbsp;
              <span className="bold">
                {getAddressDisplay(approval.operatorContractAddress)}
              </span>
              &nbsp;for:&nbsp;
              <span className="bold">
                {getAddressDisplay(approval.approvalContractAddress)}
              </span>
              {!!approval.canRevoke && (
                <span className={styles.walletApprovalStatus}>
                  {approval.approved ? (
                    <RevokeButton
                      isOBO={approval.isObo}
                      saleContractAddress={approval.operatorContractAddress}
                      tokenContractABI={
                        JSON.parse(approval.approvalContractAbidata).abi
                      }
                      tokenContractAddress={approval.approvalContractAddress}
                    />
                  ) : (
                    'Pending...'
                  )}
                </span>
              )}
            </div>
          ))) ||
          'No approvals have been granted.'}
      </div>

      <br />
      <br />
      <br />
      <br />

      <CreditCardSection />

      {/* 2FA */}
      <h4 className={MPFonts.titleSmall}>Security and Authentication</h4>

      <p className={MPFonts.bodyLarge}>Two-Factor Authentication with Email</p>

      <p className={MPFonts.bodyMedium}>
        Two-Factor Authentication (2FA) is an extra layer of security to ensure
        that only you have the ability to log in and perform sensitive actions
        on your account
      </p>
      <br />

      {/* TODO: change disabled button to usable "disable" button+action */}
      <MPActionButton
        type="button"
        size="large"
        onClick={enableEmail2FA}
        disabled={
          session.account.twoFactorEmailEnabled ||
          session.account.twoFactorSmsEnabled
        }
      >
        Enable Email 2FA
      </MPActionButton>

      {!!email2FAError && (
        <span className={styles.rightSideErrorMessage}>{email2FAError}</span>
      )}

      {/* TODO: change from dialogs to inline forms */}
      <TwoFactorDialog
        channel="email"
        isOpen={isTwoFactorDialogOpen}
        cancel={closeTwoFactorDialog}
        setSendCodeError={setEmail2FAError}
      />

      <br />
      <br />

      <p className={MPFonts.bodyLarge}>Two-Factor Authentication with SMS</p>

      <p className={MPFonts.bodyMedium}>
        Add your phone as a backup method for an extra layer of security
      </p>

      {/* TODO: change disabled button to usable "disable" button+action */}
      <MPActionButton
        type="button"
        size="large"
        onClick={enableSMS2FA}
        disabled={
          session.account.twoFactorEmailEnabled ||
          session.account.twoFactorSmsEnabled
        }
      >
        Enable SMS 2FA
      </MPActionButton>

      {!!sms2FAError && (
        <span className={styles.rightSideErrorMessage}>{sms2FAError}</span>
      )}

      {/* TODO: change from dialogs to inline forms */}
      <TwoFactorCollectPhoneDialog
        isOpen={isTwoFactorCollectPhoneDialogOpen}
        cancel={closeTwoFactorCollectPhoneDialog}
        setSendCodeError={setSMS2FAError}
      />

      <br />
      <br />
      <br />
      <br />

      {/* 2FA */}
      <h4 className={MPFonts.titleSmall}>Other Logged-in Devices</h4>
      <p className={MPFonts.bodyMedium}>
        Click below to log out of all sessions across all other devices except
        this current session. Doing this periodically can help keep your account
        safe.
      </p>

      {session.account.numSessions === 1 ? (
        <p className={joinClasses(MPFonts.bodyMedium, styles.onlyOneSession)}>
          You are only logged in on this current session.
        </p>
      ) : (
        <div>
          <MPActionButton
            type="button"
            size="large"
            onClick={logOutOfAllOtherSessions}
            isLoading={isInvalidatingSessions}
          >
            Log Out of All Other Sessions
          </MPActionButton>

          {!!invalidateSessionsError && (
            <span className={styles.rightSideErrorMessage}>
              {invalidateSessionsError}
            </span>
          )}
        </div>
      )}

      <br />
      <br />
      <br />
      <br />
    </StoreLayout>
  );
}

function EditAccountPage() {
  const [emailVerificationQueryRef] =
    useLoadQuery<AccountEmailVerificationQuery>(AccountEmailVerification, {});

  const [walletApprovalsGrantedQueryRef] =
    useLoadQuery<AccountWalletApprovalsGrantedQuery>(
      AccountWalletApprovalsGranted,
      {}
    );

  return (
    <div className={styles.editAccountPageContent}>
      {/* TODO: this navbar belongs one level up in StorePage.tsx */}
      <SideNavBar1point5 />
      <DefaultSuspense>
        <div className={styles.editAccountPageContentRight}>
          {!!emailVerificationQueryRef && (
            <EditAccountContents
              emailVerificationQueryRef={emailVerificationQueryRef}
              walletApprovalsGrantedQueryRef={walletApprovalsGrantedQueryRef}
            />
          )}
        </div>
      </DefaultSuspense>
    </div>
  );
}

export default EditAccountPage;
