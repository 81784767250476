import {
  MPActionButton,
  MPActionButtonProps,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import Contract from './Contract';
import { useGetApprovals } from './PendingApprovalsManager';

import * as styles from 'css/pages/settings/wallet/wallets/ManageWalletDialog/Contracts/index.module.css';

import { type Wallets } from 'Session';

function ContractsDialog({ isOpen, cancel, address }) {
  const buttons = [];
  const [contracts] = useSession().contracts.useReadWrite();

  return (
    <StackStateDialog
      title="Approve Contracts"
      open={isOpen}
      onClose={cancel}
      actionButton={buttons}
    >
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
        {contracts.map((contract) => (
          <Contract
            className={styles.contract}
            contract={contract}
            key={contract.address}
            walletAddress={address}
          />
        ))}
      </div>
    </StackStateDialog>
  );
}

interface ContractsProps {
  simpleDialogController: ReturnType<typeof useSimpleDialogController>;
  wallet: Wallets[0];
  variant?: MPActionButtonProps['variant'];
}

export default function Contracts({
  wallet,
  simpleDialogController,
  variant = 'primary',
}: ContractsProps) {
  const [isContractsDialogOpen, openContractsDialog, closeContractsDialog] =
    simpleDialogController;
  const [hasPendingApprovals] = useGetApprovals();
  return isContractsDialogOpen || hasPendingApprovals ? (
    <>
      <MPActionButton
        variant={variant}
        fullWidth
        size="large"
        onClick={openContractsDialog}
      >
        Approve&nbsp;Required&nbsp;Contracts
      </MPActionButton>
      {!!isContractsDialogOpen && (
        <ContractsDialog
          isOpen={isContractsDialogOpen}
          cancel={closeContractsDialog}
          address={wallet.address}
        />
      )}
    </>
  ) : null;
}
