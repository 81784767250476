import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { MPFonts, useIsMobile } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import GetAutocomplete from 'graphql/__generated__/AutocompleteQuery.graphql';

import ProfileIcon from 'components/avatar/ProfileIcon';
import SearchInput from 'components/input/SearchInput';
import Panel from 'components/panels/Panel';
import ROUTES from 'constants/Routes';
import {
  useResetAllArtworkFilterState,
  useSetQueryRecoilState,
} from 'pages/explore2/artwork/UseArtworkFilterState';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import getProfileImage from 'utils/getProfileImage';
import useNavbarGTM from 'utils/GTM/navbar';
import replaceWhitespaceWithNonBreakingSpace from 'utils/replaceWhitespaceWithNonBreakingSpace';

import LinkSection, { NavLink } from './LinkSection';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';
import * as styles from 'css/components/Navbar/Unified/SearchPanel.module.css';

// Hack until we get better search results, and a decent API.
function stripOutProductSlug(url) {
  let { pathname } = new URL(url);
  if (pathname.endsWith('/')) pathname = pathname.slice(0, -1);
  return pathname.split('/').pop();
}

type TypeAheadObj = {
  autocomplete: {
    allowedCollections: boolean;
    collectors: Array<{
      fullName: string;
      storeSlug: string;
      userProfileUrl: string;
      username: string;
    }>;
    creations: Array<{ productUrl: string; title: string }>;
    creators: Array<{
      fullName: string;
      storeSlug: string;
      userProfileUrl: string;
      username: string;
    }>;
  };
};

export default function SearchPanel({ open, onClose, ...props }) {
  const isMobile = useIsMobile();
  const modalProps = useMemo(
    () => ({
      container: window.parent.document.body,
    }),
    []
  );

  const showExplore3 = useGateValue('gate_explore_v3');
  const reloadDocument = useContext(IsDjangoContext);
  const inputRef = useRef<HTMLInputElement>();
  const [_searchValue, setSearchValue] = useState('');
  const searchValue = _searchValue.trim();
  const searchValueRef = useRef(searchValue);
  // Mirrored for use in debouncer
  searchValueRef.current = searchValue;
  const environment = useRelayEnvironment();
  const [autoCompleteObj, setAutoCompleteObj] =
    useState<TypeAheadObj['autocomplete']>();

  const navigate = useNavigate();
  const resetAllArtworksQueryParams = useResetAllArtworkFilterState();
  const setExploreQuery = useSetQueryRecoilState();

  const Track = useNavbarGTM();

  const setExploreParams = useCallback(() => {
    resetAllArtworksQueryParams();
    setExploreQuery(searchValue);
    Track.search(searchValue);
    onClose();
  }, [
    resetAllArtworksQueryParams,
    setExploreQuery,
    onClose,
    searchValue,
    Track,
  ]);

  const handleChange = useCallback(() => {
    if (showExplore3) {
      Track.search(searchValue);
      navigate(ROUTES.SEARCH.WITH_QUERY(searchValue));
      setSearchValue(() => '');
      onClose();
    } else if (!showExplore3) {
      if (reloadDocument) {
        window.parent.location = ROUTES.EXPLORE.QUERY(searchValue) as any;
      } else {
        setExploreParams();
        navigate(ROUTES.EXPLORE.QUERY(searchValue));
      }
    }
    setSearchValue((v) => v.trim());
  }, [
    showExplore3,
    searchValue,
    Track,
    onClose,
    reloadDocument,
    setExploreParams,
    navigate,
  ]);

  // Eslint is unable to determine dependencies from debounce.
  /* eslint-disable react-hooks/exhaustive-deps */
  const loadSuggestedOptions = useCallback(
    debounce((value, callback) => {
      fetchQuery(environment, GetAutocomplete, {
        str: searchValueRef.current,
      }).subscribe({
        next: (options?: TypeAheadObj) => {
          if (searchValueRef.current !== value) return;
          callback(options?.autocomplete);
        },
      });
    }, 200),
    [environment, GetAutocomplete]
  );

  useEffect(() => {
    if (searchValue.length <= 2) return;
    loadSuggestedOptions(searchValue, setAutoCompleteObj);
  }, [searchValue]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Most likely not needed since closing the drawer appears to cause everything to unmount.
  useEffect(() => {
    if (open) {
      inputRef.current?.focus?.();
    }
  }, [open]);

  return (
    <Panel
      isNav
      anchor="left"
      trackingTitle="Search"
      open={open}
      onClose={onClose}
      {...props}
      ModalProps={modalProps}
    >
      <div className={panelStyles.bodyWithoutFooter}>
        <div className={joinClasses(panelStyles.panelBody, styles.panelBody)}>
          <SearchInput
            ref={inputRef}
            value={_searchValue}
            variant={isMobile ? 'light' : 'dark'}
            setValue={setSearchValue}
            onChange={handleChange}
          />
          {searchValue.length > 2 && (
            <>
              {!!autoCompleteObj?.creations.length && (
                <LinkSection title="Artworks">
                  {autoCompleteObj.creations.map((artwork) => (
                    <NavLink
                      key={artwork.productUrl}
                      to={ROUTES.NFT(stripOutProductSlug(artwork.productUrl))}
                      reloadDocument={reloadDocument}
                      fontClassName={MPFonts.textNormalRegular}
                      trackItem="SEARCH_LOOKAHEAD_LINK_ARTWORK"
                    >
                      <span className="ellipsis">
                        {replaceWhitespaceWithNonBreakingSpace(artwork.title)}
                      </span>
                    </NavLink>
                  ))}
                </LinkSection>
              )}
              {!!autoCompleteObj?.creators.length && (
                <LinkSection title="Artists">
                  {autoCompleteObj.creators.map((creator) => (
                    <NavLink
                      key={creator.storeSlug}
                      to={ROUTES.PROFILE.SHOP(creator.storeSlug)}
                      reloadDocument={reloadDocument}
                      fontClassName={MPFonts.textNormalRegular}
                      trackItem="SEARCH_LOOKAHEAD_LINK_CREATOR"
                    >
                      <div className={styles.userRow}>
                        <ProfileIcon
                          className={styles.profileIcon}
                          source={getProfileImage(creator.userProfileUrl)}
                          alt={creator.username}
                        />
                        <span className="ellipsis">
                          {generateFormattedUserFullName(creator.username)}
                        </span>
                      </div>
                    </NavLink>
                  ))}
                </LinkSection>
              )}
              {!!autoCompleteObj?.collectors.length && (
                <LinkSection title="Collectors">
                  {autoCompleteObj.collectors.map((collector) => (
                    <NavLink
                      key={collector.storeSlug}
                      to={ROUTES.PROFILE.GALLERY(collector.storeSlug)}
                      reloadDocument={reloadDocument}
                      fontClassName={MPFonts.textNormalRegular}
                      trackItem="SEARCH_LOOKAHEAD_LINK_COLLECTOR"
                    >
                      <div className={styles.userRow}>
                        <ProfileIcon
                          className={styles.profileIcon}
                          source={getProfileImage(collector.userProfileUrl)}
                          alt={collector.username}
                        />
                        <span className="ellipsis">
                          {generateFormattedUserFullName(collector.username)}
                        </span>
                      </div>
                    </NavLink>
                  ))}
                </LinkSection>
              )}
            </>
          )}
        </div>
      </div>
    </Panel>
  );
}
