import { lazy, useCallback, useEffect, useRef, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as JotaiProvider } from 'jotai';
import { debounce } from 'lodash';
import AboutRoute from 'pages/about';
import ActivityRoute from 'pages/activity';
import CollectorsRoute from 'pages/collectors';
import CreatorsRoute from 'pages/creators';
import DiscoverRoute from 'pages/discover';
import ExhibitionRoute, { DropsRoute } from 'pages/drops';
import { ExploreProvider } from 'pages/explore';
import FAQRoute from 'pages/faq';
import HomePageRoute from 'pages/homepage';
import LoginRoute from 'pages/login';
import LogoutRoute from 'pages/logout';
import MarketplaceRoute from 'pages/marketplace';
import OnBoardRoute from 'pages/onboard';
import ProductRoute from 'pages/product';
import ProfileRedirectRoute from 'pages/profile';
import RegisterRoute from 'pages/register';
import SearchRoute from 'pages/search';
import SendRoute from 'pages/send';
import SettingsRoute from 'pages/settings';
import StoreRoute from 'pages/store';
import YourRoute from 'pages/your';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { RecoilRoot, useRecoilCallback } from 'recoil';
import { EnvironmentKey, RecoilRelayEnvironmentProvider } from 'recoil-relay';
import { WagmiProvider } from 'wagmi';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { MPTheme } from '@mp-frontend/core-components';

import FourOFourErrorBoundary from 'components/ErrorBoundaries/FourOFourErrorBoundary';
import RedirectErrorBoundary from 'components/ErrorBoundaries/RedirectErrorBoundary';
import SessionErrorBoundary from 'components/ErrorBoundaries/SessionErrorBoundary';
import SiteDownErrorBoundary from 'components/ErrorBoundaries/SiteDownErrorBoundary';
import FullScreenOverlayExperiment from 'components/FullScreenOverlayExperiment';
import Navbar from 'components/Navbar/Unified';
import SiteLoadingSuspense from 'components/SiteLoadingSuspense';
import SiteNotice from 'components/SiteNotice';
import StatsigProvider from 'components/StatsigProvider';
import { APP_NAME } from 'constants/Utils';
import { ApprovedCreatorRegistryQuery } from 'hooks/contracts/ApprovedCreatorRegistry';
import { RecoilGlobalBidCoreContractQuery } from 'hooks/contracts/useGlobalDigitalMediaBidCoreContract';
import { RankedAuctionCoreContractQuery } from 'hooks/contracts/useRankedAuctionCoreContract';
import { VaultCoreContractQuery } from 'hooks/contracts/VaultCore';
import useAnimation from 'hooks/useAnimation';
import useBodyRef, { useSetBodyRef } from 'hooks/useBodyRef';
import { useSetBodyResize } from 'hooks/useOnBodyResize';
import {
  RecoilDropSaleContractQuery,
  RecoilSaleContractQuery,
} from 'hooks/useSaleContract';
import useSentryConfig from 'hooks/useSentryConfig';
import useStripePromise from 'hooks/useStripePromise';
import CreateOptions from 'pages/create-options';
import DigitalMediaRoute from 'pages/digital-media';
import ExploreRoute from 'pages/explore2';
import ForgotPasswordRoute from 'pages/forgot-password';
import ImportTokensRoute from 'pages/importTokens';
import OpenEditionRoute from 'pages/openEdition';
import StoreMP1point5Route from 'pages/store/mp1point5Index';

import RelayEnvironment from './RelayEnvironment';
import useWagmiClient from './wagmi-client';

import 'css/global/Reset.css';
import 'css/global/Global.css';
// Shadows
import 'css/global/shadows/ShadowVariables.css';
// Transitions
import 'css/global/transitions/TransitionVariables.css';
import * as styles from 'css/App.module.css';

import { GlobalStyleProvider } from 'contexts/GlobalStyleContext';
import jotaiStore from 'jotaiStore';

const ProfilesRoute = lazy(() => import('pages/profiles'));

const SupportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

function Layout() {
  const viewportRef = useRef(null);
  const [oneHundredVh, setOneHundredVh] = useState('100%');

  const setBodyRef = useSetBodyRef();
  useSetBodyResize(useBodyRef());

  // For mobile browsers, 100% is the height of viewport with the address bar(caveat you must pass the height down), 100vh is the height without.
  const changeHeightDueToAddressBar = debounce(() => {
    setOneHundredVh(
      viewportRef.current &&
        viewportRef.current.clientHeight &&
        viewportRef.current.clientHeight === window.innerHeight
        ? '100vh'
        : '100%'
    );
  }, 100);

  useEffect(
    () => {
      if (window) {
        changeHeightDueToAddressBar();
        window.addEventListener('resize', changeHeightDueToAddressBar);
      }
      return () => {
        if (window)
          window.removeEventListener('resize', changeHeightDueToAddressBar);
      };
    },
    // Run Once, don't care
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  const bodyRef = useCallback(
    (el) => {
      setBodyRef(el);
    },
    [setBodyRef]
  );

  useAnimation();

  return (
    <SiteLoadingSuspense>
      <ExploreProvider>
        <div
          ref={viewportRef}
          style={{
            height: '100vh',
            left: '-500px',
            position: 'absolute',
            visibility: 'hidden',
          }}
        />
        <Navbar />
        <FourOFourErrorBoundary>
          <RedirectErrorBoundary>
            <div
              id="app"
              className={styles.appBody}
              style={{ '--oneHundredVh': oneHundredVh } as any}
            >
              <div className={styles.bodyWrapper} ref={bodyRef}>
                <SiteNotice />
                <SiteLoadingSuspense>
                  <Outlet />
                </SiteLoadingSuspense>
              </div>
            </div>

            <FullScreenOverlayExperiment />
          </RedirectErrorBoundary>
        </FourOFourErrorBoundary>
      </ExploreProvider>
    </SiteLoadingSuspense>
  );
}

function Init() {
  const [stripePromise, options] = useStripePromise();
  useSentryConfig();

  const queryClient = new QueryClient();

  const PreloadRecoilQueries = useRecoilCallback(({ snapshot }) => () => {
    snapshot.getLoadable(ApprovedCreatorRegistryQuery);
    snapshot.getLoadable(RecoilSaleContractQuery);
    snapshot.getLoadable(RecoilDropSaleContractQuery);
    snapshot.getLoadable(RecoilGlobalBidCoreContractQuery);
    snapshot.getLoadable(RankedAuctionCoreContractQuery);
    snapshot.getLoadable(VaultCoreContractQuery);
    snapshot.retain();
  });
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(PreloadRecoilQueries, []);

  return (
    <Elements stripe={stripePromise} options={options}>
      <WagmiProvider config={useWagmiClient()}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                {AboutRoute}
                {ActivityRoute}
                {CollectorsRoute}
                {CreateOptions}
                {CreatorsRoute}
                {DigitalMediaRoute}
                {DiscoverRoute}
                {ExhibitionRoute}
                {DropsRoute}
                {ImportTokensRoute}
                {FAQRoute}
                {LoginRoute}
                {LogoutRoute}
                {MarketplaceRoute}
                {OnBoardRoute}
                {RegisterRoute}
                {SearchRoute}
                {ProfileRedirectRoute}
                {StoreMP1point5Route}
                {StoreRoute}
                {ExploreRoute}
                {HomePageRoute}
                {ProductRoute}
                {OpenEditionRoute}
                {SendRoute}
                {SettingsRoute}
                {YourRoute}
                {ForgotPasswordRoute}
                <Route path="*" element={<ProfilesRoute />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </WagmiProvider>
    </Elements>
  );
}

const AppEnvironmentKey = new EnvironmentKey(APP_NAME);

function Env() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MPTheme}>
        <GlobalStyleProvider>
          <RecoilRoot>
            <JotaiProvider store={jotaiStore}>
              <RecoilRelayEnvironmentProvider
                environment={RelayEnvironment}
                environmentKey={AppEnvironmentKey}
              >
                <SiteLoadingSuspense>
                  <SiteDownErrorBoundary>
                    <SessionErrorBoundary>
                      <DndProvider
                        backend={SupportsTouch ? TouchBackend : HTML5Backend}
                      >
                        <StatsigProvider>
                          <Init />
                        </StatsigProvider>
                      </DndProvider>
                    </SessionErrorBoundary>
                  </SiteDownErrorBoundary>
                </SiteLoadingSuspense>
              </RecoilRelayEnvironmentProvider>
            </JotaiProvider>
          </RecoilRoot>
        </GlobalStyleProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Env;
