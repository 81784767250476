const LOCAL_STORAGE_KEY_PREFIX = 'mp:';
export const LOCAL_STORAGE_KEYS = {
  DOWNGRADED_CREATOR_BANNER_SEEN: `${LOCAL_STORAGE_KEY_PREFIX}dcb-seen`,
  PRODUCT_PAGE_PASSWORD: `${LOCAL_STORAGE_KEY_PREFIX}pdp-pass`,
  VISITED_COUNT: `${LOCAL_STORAGE_KEY_PREFIX}visited-count`,
};

export const getViewCount = (
  localStorageKey: string = LOCAL_STORAGE_KEYS.VISITED_COUNT
): number => {
  const visitedCount = localStorage.getItem(localStorageKey);
  if (!visitedCount) {
    const initialValue = 0;
    localStorage.setItem(localStorageKey, initialValue.toString());
    return initialValue;
  }
  return parseInt(visitedCount, 10);
};

export const incrementViewCount = (
  localStorageKey: string = LOCAL_STORAGE_KEYS.VISITED_COUNT
): number => {
  const newVisitedCount = getViewCount(localStorageKey) + 1;
  localStorage.setItem(localStorageKey, newVisitedCount.toString());
  return newVisitedCount;
};

export default {
  getViewCount,
  incrementViewCount,
};
