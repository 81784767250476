import { useEffect, useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { useGateValue } from '@statsig/react-bindings';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import { EVENT_EXPLORE_GRID_READY } from 'components/explore2/ExploreGrid';
import ROUTES from 'constants/Routes';
import { APP_NAME } from 'constants/Utils';
import useBodyRef from 'hooks/useBodyRef';
import { ExploreType } from 'pages/explore/types';
import ArtworkContent from 'pages/explore2/artwork/ArtworkContent';
import setDocTitle from 'utils/setDocTitle';

import GenreFilterTabs from './GenreFilterTabs';
import { useMapQueryParamsToFilterStateEffect } from './UseArtworkFilterState';

import * as styles from 'css/pages/explore2/artwork/ArtworkPage.module.css';

const artworkPageOffsetAtom = atom({
  default: 0,
  key: 'artworkPageOffsetAtom',
});

function ArtworkPage() {
  const showExplore3 = useGateValue('gate_explore_v3');

  useEffect(() => setDocTitle(`Explore Artworks | ${APP_NAME}`));

  const bodyRef = useBodyRef();
  const navigate = useNavigate();

  const [scrollOffset, setScrollOffset] = useRecoilState(artworkPageOffsetAtom);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigationType = useNavigationType();
  const [domSelfNode, domSelfRef] = useRefState<HTMLDivElement>(null);

  useEffect(() => {
    if (!showExplore3) return;

    navigate(ROUTES.HOME.WITH_EXPLORE(ExploreType.Artworks));
  }, [showExplore3, navigate]);

  useEffect(() => {
    const listenForNavigation = function ListenForNavigation(ev) {
      if (ev.target.closest('a')) {
        setScrollOffset(bodyRef?.firstChild.scrollTop);
      }
    };
    bodyRef?.addEventListener('click', listenForNavigation);
    return () => bodyRef?.removeEventListener('click', listenForNavigation);
  }, [bodyRef, setScrollOffset]);

  useMapQueryParamsToFilterStateEffect();

  useEffect(() => {
    const setInit = () => setIsInitialized(true);
    domSelfNode?.addEventListener(EVENT_EXPLORE_GRID_READY, setInit);
    return () =>
      domSelfNode?.removeEventListener(EVENT_EXPLORE_GRID_READY, setInit);
  }, [setIsInitialized, domSelfNode]);

  useEffect(() => {
    if (navigationType === 'POP' && bodyRef && isInitialized) {
      bodyRef.firstChild.scrollTop = scrollOffset;
    }
    // We only want to attempt to scroll when clicking back,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isInitialized, bodyRef, navigationType]);

  return (
    <div ref={domSelfRef}>
      <p className={joinClasses(MPFonts.headline4, styles.title1)}>Explore</p>
      <p className={joinClasses(MPFonts.headline2, styles.title2)}>Artworks</p>
      <div className={joinClasses('flexCenter', styles.genres)}>
        <GenreFilterTabs />
      </div>
      <DefaultErrorBoundary>
        <ArtworkContent />
      </DefaultErrorBoundary>
    </div>
  );
}

export default ArtworkPage;
