import { Route } from 'react-router-dom';

import ContractsPage from './contracts/ContractsPage';
import WalletPage from './wallet';

const SettingsRoute = (
  <Route path="settings">
    <Route path="wallets" element={<WalletPage />} />
    <Route path="contracts">
      <Route index element={<ContractsPage />} />
    </Route>
  </Route>
);

export default SettingsRoute;
