import { CSSProperties } from 'react';

import { MPAnimations, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import MPLogo from 'components/icons/MPLogo';
import {
  DISCORD_MP_PROFILE_URL,
  INSTAGRAM_MP_PROFILE_URL,
  LEVER_CAREERS_URL,
  MEDIUM_MP_PROFILE_URL,
  TWITTER_MP_PROFILE_URL,
} from 'constants/ExternalUrls';
import ROUTES from 'constants/Routes';

import * as styles from 'css/components/Footer.module.css';

type Link = {
  name: string;
  url: string;
  ctaDetail?: string;
  ctaModule?: string;
  external?: boolean;
};

const HELP_LINKS: ReadonlyArray<Link> = [
  {
    ctaDetail: ROUTES.CONCIERGE(),
    ctaModule: 'concierge_program',
    name: 'Concierge Program',
    url: ROUTES.CONCIERGE(),
  },
  {
    name: 'FAQ',
    url: ROUTES.FAQ(),
  },
  {
    name: 'Support',
    url: `${ROUTES.FAQ()}#support`,
  },
  {
    name: 'Your Purchases',
    url: ROUTES.YOUR.PURCHASES(),
  },
  {
    name: 'Privacy Policy',
    url: ROUTES.PRIVACY(),
  },
];

const COMPANY_LINKS: ReadonlyArray<Link> = [
  {
    name: 'About',
    url: ROUTES.ABOUT(),
  },
  {
    name: 'Mission & Team',
    url: ROUTES.ABOUT.VALUES(),
  },
  {
    name: 'Terms of Service',
    url: ROUTES.TERMS(),
  },
  {
    external: true,
    name: 'Careers',
    url: LEVER_CAREERS_URL,
  },
];

const SOCIAL_LINKS: ReadonlyArray<Link> = [
  {
    name: 'Discord',
    url: DISCORD_MP_PROFILE_URL,
  },
  {
    name: 'Instagram',
    url: INSTAGRAM_MP_PROFILE_URL,
  },
  {
    name: 'Twitter',
    url: TWITTER_MP_PROFILE_URL,
  },
  {
    name: 'Medium',
    url: MEDIUM_MP_PROFILE_URL,
  },
];

interface FooterProps {
  className?: string;
  style?: CSSProperties;
}

export default function Footer({ className, style }: FooterProps) {
  return (
    <div className={styles.outerContainer}>
      <section
        className={joinClasses(className, styles.container)}
        style={style}
      >
        <div className={styles.logoAndCopyright}>
          <a className="invisibleAnchor" href={ROUTES.HOME()}>
            <MPLogo
              className={joinClasses(
                MPAnimations.Color.LightToDark,
                styles.logo
              )}
            />
          </a>
          <div
            className={joinClasses(MPFonts.paragraphXSmall, styles.copyright)}
          >
            &copy; {new Date().getFullYear()} Onchain Labs, Inc. All rights
            reserved.
          </div>
        </div>
        <ul
          title="Help"
          className={joinClasses(
            MPFonts.textNormalRegular,
            MPAnimations.Color.LightToDark,
            styles.help
          )}
        >
          {HELP_LINKS.map((link) => (
            <li key={link.name}>
              <a
                href={link.url}
                className={MPAnimations.Color.LightToDark}
                {...(link.ctaModule && link.ctaDetail
                  ? {
                      'data-cta-detail': link.ctaDetail,
                      'data-cta-module': link.ctaModule,
                    }
                  : {})}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
        <ul
          title="Company"
          className={joinClasses(MPFonts.textNormalRegular, styles.company)}
        >
          {COMPANY_LINKS.map((link) => (
            <li key={link.name}>
              <a
                href={link.url}
                className={MPAnimations.Color.LightToDark}
                {...(link.external
                  ? { rel: 'noreferrer', target: '_blank' }
                  : {})}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
        <ul
          title="Follow Us"
          className={joinClasses(MPFonts.textNormalRegular, styles.social)}
        >
          {SOCIAL_LINKS.map((link) => (
            <li key={link.name}>
              <a
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className={MPAnimations.Color.LightToDark}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
